import React from 'react';
import {Button, useRecordContext} from 'react-admin';
import { Link } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import {makeStyles} from "@material-ui/core/styles";

const EditButton = ({rec, pathEdit, params = '' }) => {
    const curVal = useRecordContext();
    const record = rec ? rec : curVal;

    if (record) {
        return (<Button
                sx={{
                    float: "right",
                    border: '1px solid #4884EF',
                    borderRadius: '4px',
                    padding: '6px 16px'
                }}
                component={Link}
                to={{pathname: '/' + pathEdit + (record.id ? ('/' + record.id + '?id=' +  record.id + params) : '')}}
                label="Edit"
                title="Edit"
            >
                <EditIcon/>
            </Button>
        );
    }

    return null;
};

export default EditButton;