export const ENV =
   {
     ENV : 'prod',
     HOST_BACKEND: 'https://ecs.api.medlog.cloud' || window.location.protocol + '//' + window.location.hostname
};








