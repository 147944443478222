import {
    Datagrid,
    TopToolbar,
    sanitizeListRestProps,
    TextField,
    ShowButton,
    useRecordContext
} from 'react-admin';
import EditButton from "../../../../Component/Layouts/Button/EditButton";
import List from "../../../../../App/Component/Redesign/ListMod";
import React from 'react';
import Breadcrumb from "../Breadcrumb";
import {DateTimeWithTimeZoneField} from "../../../../Component/Field/DateTimeWithTimeZoneField";

const idGroup = () => {
    const urlParams = new URL(document.URL.replace('#', '')).searchParams;
    return urlParams.get('idGroup');
}

const getType = () => {
    const urlParams = new URL(document.URL.replace('#', '')).searchParams;
    return urlParams.get('type');
}

const ListActions = ({basePath, className, ...rest}) => {
    return (<TopToolbar sx={{width: '100% !important'}} {...sanitizeListRestProps(rest)}>
                <Breadcrumb idVal={idGroup()} type="subgroup" {...rest} />
                <EditButton pathEdit='visit/config/subgroup' rec={{id: idGroup()}}  params={'&type=' + getType()}/>
            </TopToolbar>);
};

const ShowButtonMod = props => {
    const record = useRecordContext();
    return record ?
        (<ShowButton
            label=''
            to={{ pathname: "/visit/config/item", search: "idSubgroup="+record.id + '&type=' + getType()}}
            {...props}
        />) : null;
};

export const SubGroupConfigList = ({permissions, ...props}) => (
    <List {...props}
          actions={<ListActions />}
          filterDefaultValues={{ idGroup: idGroup() }}
          basePath="visit/config/subgroup"
    >
        <Datagrid bulkActionButtons={false}>
            <TextField label='Name' source="name" sortable={false}  />
            <TextField label='Items' source="items" sortable={false}  />
            <DateTimeWithTimeZoneField fullWidth label="Created" source="created_at"/>
            <ShowButtonMod label='' />
        </Datagrid>
    </List>
);
