import React from 'react';
import {DateField, useRecordContext} from "react-admin";
import {DateTimeFormatString} from "../Handlers/DateTimeFormat";

const DateTimeFieldMod = props => {
    const record = useRecordContext();
    /** fix for Safari (Date format must be YYYY-MM-DDTHH:mm:ss) */
    if (record && record[props.source]) {
        record[props.source] = record[props.source].date ? record[props.source].date : record[props.source];
    }

    return <DateField {...props}  />;
};

export default DateTimeFieldMod;