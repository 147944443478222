import {Edit, SimpleForm, TextInput, required, SelectInput, useRefresh, useRedirect} from "react-admin";
import React from "react";
import ActionsMod from "../../../../Component/Layouts/Button/ActionsMod";
import {VisitBillingStatusEdit} from "../constant/const";
import ToolBarButtonModEdit from "../../../../Component/Layouts/Button/ToolBarButtonModEdit";
import { useParams } from 'react-router-dom';

export const BillingVisitEdit = ({...props}) => {
    const refresh = useRefresh();
    const redirect = useRedirect();
    const {id} = useParams();

    const onSuccess = (data) => {
        redirect(data.id ? `/visit/list/${data.id}/show/billing` : 'list');
        refresh();
    };

    return (<Edit {...props}
                  mutationOptions={{ onSuccess }}
                  mutationMode="pessimistic"
                  actions={<ActionsMod title={'Billing edit'} />} undoable={false}>
        <SimpleForm toolbar={<ToolBarButtonModEdit showCancel="true" hrefCancel={`/#/visit/list/${id}/show/billing`}/>} variant="outlined" encType="multipart/form-data">
            <h4>Patient information</h4>
            <TextInput fullWidth label="First Name" validate={[required()]} source="firstName"/>
            <TextInput fullWidth label="Last Name" validate={[required()]} source="lastName"/>
            <TextInput fullWidth label="DOB" validate={[required()]} source="dob"/>
            <TextInput fullWidth label="Address" source="address"/>
            <TextInput fullWidth label="City" source="city"/>
            <TextInput fullWidth label="State" source="state"/>
            <TextInput fullWidth label="Zip" source="zip"/>
            <h4>Claim Information</h4>
            <TextInput fullWidth label="DOS" source="dateService"/>
            <TextInput fullWidth label="Provider" source="provider"/>
            <TextInput fullWidth label="Practice Facility" validate={[required()]} source="facility"/>
            <TextInput fullWidth label="Admission Date" source="admissionDate"/>
            <TextInput fullWidth label="CPT" source="cpt"/>
            <TextInput fullWidth label="ICD-10" source="icd"/>
            <TextInput fullWidth label="Type of Care" source="type"/>
            <TextInput fullWidth label="Report Type" source="reportType"/>
            <h4>Insurance Information</h4>
            <TextInput fullWidth label="Primary Name" source="primary" emptyText="-"/>
            <TextInput fullWidth label="Primary ID" source="primaryID" emptyText="-"/>
            <TextInput fullWidth label="Secondary Name" source="secondary" emptyText="-"/>
            <TextInput fullWidth label="Secondary ID" source="secondaryID" emptyText="-"/>
            <TextInput fullWidth label="Tertiary Name" source="tertiary" emptyText="-"/>
            <TextInput fullWidth label="Tertiary ID" source="tertiaryID" emptyText="-"/>
            <TextInput fullWidth label="Medicare ID" source="medicareId" emptyText="-"/>
            <TextInput fullWidth label="Medicaid ID" source="medicaid" emptyText="-"/>
            <h4>Billing details</h4>
            <SelectInput fullWidth label="Billed" source="isBilled" choices={VisitBillingStatusEdit}/>
        </SimpleForm>
    </Edit>);
}
