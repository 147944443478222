import React from "react";
import {Datagrid, TextField} from 'react-admin';
import ListMod from "../../Component/Redesign/ListMod";
import CrudMod from "../../Component/Layouts/Button/CrudMod";
import {DateTimeWithTimeZoneField} from "../../Component/Field/DateTimeWithTimeZoneField";

export const LegalList = ({permissions, ...props}) => (
    <ListMod {...props}
             title="Legal"
             basePath="/legal"
    >
        <Datagrid bulkActionButtons={false}>
            <TextField label="Legal" source="title" sortBy="title"/>
            <DateTimeWithTimeZoneField fullWidth label="Last update" source="created_at"/>
            <CrudMod edit="true" />
        </Datagrid>
    </ListMod>
);
