import React from 'react';
import {
    Datagrid,
    TextField,
    ShowButton,
    EditButton,
    DeleteWithConfirmButton,
    SelectField,
    TopToolbar,
    SelectInput,
    Filter,
    TextInput,
    sanitizeListRestProps,
    CreateButton,
    ChipField, useRecordContext,
} from 'react-admin';
import ListMod from "../../../../App/Component/Redesign/ListMod";
import {UserCredentials, UserStatus} from "./constant/userConst";
import Chip from '@material-ui/core/Chip';
import classnames from 'classnames';
import {makeStyles} from "@material-ui/core/styles";
import {IdValueFromConst, LabelValueFromConst} from "../../../Component/Handlers/ValueFromConst";
import CreateButtonMod from "../../../Component/Layouts/Button/CreateButton";

const useStyles = makeStyles({
    active: { color: 'white', background: '#32A232'},
    blocked: { color: 'white', background: '#52303336' },
    invited: { color: 'white', background: '#4884EF' }
});

const StatusField = props => {
    const classes = useStyles();
    const record = useRecordContext();

    let className = classnames({
        [classes.active]: record[props.source] === IdValueFromConst(UserStatus, 'Active'),
        [classes.blocked]: record[props.source] === IdValueFromConst(UserStatus, 'Blocked'),
        [classes.invited]: record[props.source] === IdValueFromConst(UserStatus, 'Invited'),
    })

    return (
        <Chip
            className={className}
            label={LabelValueFromConst(UserStatus, record[props.source], '-')}
        />
    );
};

const Filters = (props) => (
    <Filter {...props}>
        <TextInput variant="standard" label="Search by name:" source="name" alwaysOn />
        <TextInput variant="standard" label="Search by phone:" source="phone" alwaysOn />
        <TextInput variant="standard" label="Search by facility:" source="facility" alwaysOn />
        <SelectInput variant="standard" label="Credentials" emptyText={"All"} emptyValue={''}  source="credential" choices={UserCredentials} alwaysOn />
        <SelectInput variant="standard" label="Status" emptyText={"All"} emptyValue={''}  source="status" choices={UserStatus} alwaysOn />
    </Filter>
);

const ListActions = ({basePath, className, ...rest}) => {
    return (<TopToolbar className={className} {...sanitizeListRestProps(rest)}>
                <CreateButtonMod basePath={basePath} label={'Invite nurse'} />
            </TopToolbar>);
};

export const NursesList = ({permissions, ...props}) => (
    <ListMod {...props}
             actions={<ListActions />}
             basePath="/nurses"
             filters={<Filters />}
    >
        <Datagrid bulkActionButtons={false}>
            <TextField label='Name' source="name" sortBy="username"/>
            <SelectField source="credentials" choices={UserCredentials}/>
            <TextField label='Phone number' source="phone"/>
            <TextField label='Facilities' source="facilityCount"/>
            <StatusField source="status" />
            <EditButton label={''}/>
        </Datagrid>
    </ListMod>
);