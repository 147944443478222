import {
    Edit,
    SimpleForm,
    TextInput,
    required,
    Toolbar,
    SaveButton,
    AutocompleteInput,
    SelectInput,
    useRecordContext,
    ReferenceInput, FormDataConsumer
} from "react-admin";
import React from "react";
import ActionsMod from "../../../../Component/Layouts/Button/ActionsMod";
import {stayType} from "./const";
import BackButton from "../../../../Component/Layouts/Button/BackButton";
import ToolBarButtonModEdit from "../../../../Component/Layouts/Button/ToolBarButtonModEdit";
import { useLocation } from "react-router-dom";
import {CustomAutoComplete} from "../../../../Component/Field/CustomAutocomplete";

const urlParams = new URL(document.URL.replace('#', '')).searchParams;

export const BillerEncounterEdit = ({...props}) => {
    const { pathname } = useLocation();
    let id = pathname.split('/').pop();
    const stateFilterQuery = searchText => ({ name: searchText });

    return (<Edit
        redirect={urlParams.has('id') ? `/smarttext/encounters/list/billing/${id}/edit/` : 'show'}
        {...props} actions={<ActionsMod title={'Billing edit'}/>} undoable={false}>
        <SimpleForm toolbar={<ToolBarButtonModEdit showCancel="true" hrefCancel={`/#/smarttext/encounters/list/billing/${id}/show/`}/>}
                    variant="outlined"
                    encType="multipart/form-data">
            <h4>Patient</h4>
            <TextInput fullWidth label="First Name" validate={[required()]} source="billing.firstName"/>
            <TextInput fullWidth label="Last Name" validate={[required()]} source="billing.lastName"/>
            <TextInput fullWidth label="DOB" validate={[required()]} source="billing.dob"/>
            <TextInput fullWidth label="Address" source="billing.address"/>
            <TextInput fullWidth label="City" source="billing.city"/>
            <ReferenceInput fullWidth reference="states/list" source="billing.state">
                <AutocompleteInput label="State" perPage={50} filterToQuery={stateFilterQuery} optionValue="name" optionText="name"/>
            </ReferenceInput>
            <TextInput fullWidth label="Zip" source="billing.zip"/>
            <TextInput fullWidth label="Medical record number" source="billing.medicalRecordNumber"/>
            <TextInput fullWidth label="Provider" validate={[required()]} source="billing.provider"/>
            <TextInput fullWidth label="Practice" source="billing.practice"/>
            <TextInput fullWidth label="Facility" validate={[required()]} source="billing.facility"/>
            <TextInput fullWidth label="Medicare ID" source="billing.medicareID"/>
            <TextInput fullWidth label="Medicaid ID" source="billing.medicaidId"/>
            <h4>Insurance</h4>
            <TextInput fullWidth label="Primary" source="billing.primary"/>
            <TextInput fullWidth label="Primary ID" source="billing.primaryID"/>
            <TextInput fullWidth label="Secondary" source="billing.secondary"/>
            <TextInput fullWidth label="Secondary ID" source="billing.secondaryID"/>
            <TextInput fullWidth label="Tertiary" source="billing.tertiary"/>
            <TextInput fullWidth label="Tertiary ID" source="billing.tertiaryID"/>
            <h4>Encounter</h4>
            <TextInput fullWidth label="Date of service" validate={[required()]} source="billing.dos"/>
            <TextInput fullWidth label="Admission date" source="billing.admissionDate"/>
            <SelectInput fullWidth label="Stay type" source="billing.stayType" choices={stayType}
                         validate={[required()]}/>
            <TextInput fullWidth label="Suggested CPT" source="billing.suggestedCpt"/>
            <FormDataConsumer>
                { ({ formData, ...rest }) => (
                    <CustomAutoComplete
                        fullWidth
                        label="ICD-10 code"
                        icd={ formData.billing.icd }
                        source="billing.icd"
                        filterQuery={'icd'}
                        url={'visit/problem/list/'}
                        validate={ required() }
                        { ...rest }/>
                ) }
            </FormDataConsumer>
            <TextInput disabled={true} fullWidth label="Documented time" source="billing.documentedTime"/>
        </SimpleForm>
    </Edit>);
}

const SaveButtonMod = ({...props}) => {
    const record = useRecordContext();

    return <Toolbar {...props}>
        <SaveButton />
        <BackButton link={`/smarttext/encounters/list/billing/${record.id}/show/`}/>
    </Toolbar>;
};
