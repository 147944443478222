import React from 'react';
import {
    TextInput,
    Edit,
    SimpleForm,
    SelectInput,
    required,
    ArrayInput,
    SimpleFormIterator,
    ReferenceArrayInput, ReferenceInput, AutocompleteInput
} from 'react-admin';
import ToolBarButtonModEdit from "../../../Component/Layouts/Button/ToolBarButtonModEdit";
import ActionsMod from "../../../Component/Layouts/Button/ActionsMod";
import {UserStatus, UserCredentials} from "./constant/userConst";

const redirect = (basePath, id, data) => {
    return `nurses`;
};

const redirectDel = (userId) => (d) => {
    return "nurses";
};

const optionRenderer = choice => choice ? `${choice.name} ${choice.address}` : '--';

export const NursesEdit = ({...props}) => {

    return (<Edit actions={<ActionsMod title={'Edit nurse'}/>} {...props} undoable={false} mutationMode="pessimistic">
                <SimpleForm toolbar={<ToolBarButtonModEdit deleteConfirmTitle={"Delete Nurse?"} hrefCancel="/#/nurses" redirectval={redirectDel()} showDel={true}/>}
                                                   variant="outlined"
                                                   {...props}
                                                   redirect={redirect}
                                                   encType="multipart/form-data">
                    <TextInput fullWidth source="firstName" validate={[required()]}/>
                    <TextInput fullWidth source="lastName" validate={[required()]}/>
                    <SelectInput fullWidth source="credentials" choices={UserCredentials} validate={[required()]}/>
                    <TextInput fullWidth source="phone" validate={[required()]}/>
                    <SelectInput fullWidth source="status" choices={UserStatus} validate={[required()]}/>
                    <ArrayInput label="" validate={required()} sx={{'& ul' : {'& p' : { display: 'none'}}}} source="facilities">
                        <SimpleFormIterator disableReordering>
                            <ReferenceInput fullWidth label="Search by facility name or address" reference="facility/list" filterToQuery={searchText => ({ facilityAddress: searchText })}>
                                <AutocompleteInput optionText={optionRenderer}/>
                            </ReferenceInput>
                        </SimpleFormIterator>
                    </ArrayInput>
                </SimpleForm>
            </Edit>);
};
