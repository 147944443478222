import React from 'react';
import {
    Datagrid,
    TextField,
    SelectField,
    EditButton,
    TopToolbar,
    DeleteButton,
    Filter,
    TextInput,
    SelectInput, sanitizeListRestProps, ShowButton, useRecordContext,
} from 'react-admin';
import List from "../../../App/Component/Redesign/ListMod";
import CreateButtonMod from "../../Component/Layouts/Button/CreateButton";
import {makeStyles} from "@material-ui/core/styles";
import classnames from "classnames";
import Chip from "@material-ui/core/Chip";
import CrudMod from "../../Component/Layouts/Button/CrudMod";
import {status} from "./constant/const";
import {IdValueFromConst, LabelValueFromConst} from "../../Component/Handlers/ValueFromConst";

const useStyles = makeStyles({
    active: { color: 'white', background: '#32A232'},
    inactive: { color: 'white', background: '#7F8DA0' },
});

const StatusField = props => {
    const classes = useStyles();
    const record = useRecordContext();

    let className = classnames({
        [classes.active]: record[props.source] === IdValueFromConst(status, 'Active'),
        [classes.inactive]: record[props.source] === IdValueFromConst(status, 'Inactive'),
    })

    return (
        <Chip
            className={className}
            label={LabelValueFromConst(status, record[props.source], record[props.source])}
        />
    );
};

const ListActions = ({basePath, className, ...rest}) => {
    return (<TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        <CreateButtonMod basePath={basePath} label={'Add practice'} />
    </TopToolbar>);
};

const ListFilter = (props) => (
    <Filter {...props} >
        <TextInput variant="standard" label="Search by name: " source="name" alwaysOn />
    </Filter>
);



export const PracticesList = ({permissions, ...props}) => (

    <List {...props}
          title="Practices"
          basePath="/practices/list"
          actions={<ListActions/>}
          filters={<ListFilter/>}
    >
        <Datagrid bulkActionButtons={false}>
            <TextField label='Name' source="name" sortBy="name" />
            <TextField label='EIN' source="ein" />
            <TextField label='Billing NPI' source="npi" />
            <TextField label='Address' source="address" />
            <TextField label='Providers' source="provider_count" />
            <TextField label='Billers' source="biller_count" />
            <StatusField source="status" />
            <CrudMod show="true" edit="true" />
        </Datagrid>
    </List>
);