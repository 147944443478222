import {
    Edit,
    SimpleForm,
    TextInput,
    required,
    number,
    BooleanInput,
    Toolbar,
    SaveButton,
    FormDataConsumer,
    ReferenceArrayInput,
    TextField,
    SelectInput, useRedirect, ArrayInput, SimpleFormIterator, ReferenceInput, AutocompleteInput, SelectArrayInput
} from "react-admin";
import React, {useCallback} from "react";
import ActionsMod from "../../../Component/Layouts/Button/ActionsMod";
import {useForm} from "react-final-form";
import SubmitToolbar from "../../../Component/Layouts/Button/SubmitToolbarCreate";

export const FacilityIntegrationEdit = ({...props}) => {
    const transform = data => ({
        ...data,
        category: Array.isArray(data.category) ? data.category : [data.category]
    });

    const urlParams = new URL(document.URL.replace('#', '')).searchParams;

    return (<Edit mutationMode="pessimistic" redirect={urlParams.has('id') ? `/facility/list/${urlParams.get('id')}/show/integration` : 'link'} {...props} actions={<ActionsMod title={''}/>} transform={transform} undoable={false}>
        <SimpleForm toolbar={<SubmitToolbar saveButton={<SaveButtonMod/>}/>} variant="outlined" encType="multipart/form-data">
            <FormDataConsumer fullWidth>
                {({formData, ...rest}) => {
                    return <h3>
                        <label>Facility name: </label>
                        <span>{formData.name}</span>
                    </h3>
                }}
            </FormDataConsumer>
            <BooleanInput label="PointClickCare integration" source="pcc"/>
            <TextInput fullWidth label="Organization Id" validate={[required()]} source="organizationId"/>
            <FormDataConsumer fullWidth>
                {({formData, ...rest}) => {
                    return <TextInput fullWidth label="Facility Id" validate={[required(), number()]} source="facilityId"/>
                }}
            </FormDataConsumer>
            <BooleanInput label="Send visit PDFs to PointClickCare" source="sendPdf"/>
            <FormDataConsumer fullWidth>
                {({formData, ...rest}) => {
                    return (<ReferenceInput fullWidth label="Category" reference={`facility/category/list/${formData.id}`} source="category">
                                <SelectInput fullWidth variant="outlined" optionText="description"/>
                             </ReferenceInput>
                           )
                }}
            </FormDataConsumer>
        </SimpleForm>
    </Edit>);
}

const SaveButtonMod = ({...props}) => {
    return <Toolbar {...props} ><SaveButton /></Toolbar>;
};
