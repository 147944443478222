import React from 'react';
import {
    TextInput,
    Create,
    SimpleForm,
    ReferenceInput,
    required, number, minLength,
    maxLength, SelectInput, ReferenceArrayInput, FormDataConsumer, AutocompleteInput
} from 'react-admin';
import ActionsMod from "../../../Component/Layouts/Button/ActionsMod";
import {UserCredentials} from "../Admin/constant/facilityConst";
import SubmitToolbar from "../../../Component/Layouts/Button/SubmitToolbarCreate";
import ToolBarButtonModCreate from "../../../Component/Layouts/Button/ToolBarButtonModCreate";

export const FacilityAdminCreate = props => (
    <Create {...props} actions={<ActionsMod title={'Invite admin'} />}>
        <SimpleForm toolbar={<ToolBarButtonModCreate showCancel="true" hrefCancel="/#/facility/admin" textSave="Invite"/>} redirect="show" label="list" variant="outlined" encType="multipart/form-data">
            <TextInput fullWidth label="First name" source="firstName" validate={required()}/>
            <TextInput fullWidth label="Last name" source="lastName" validate={required()}/>
            <SelectInput fullWidth label="Credentials" source="credentials" validate={required()} choices={UserCredentials}/>
            <TextInput fullWidth label="Phone" source="phone" validate={[required(), number(), minLength(10, 'Must be 10 characters'), maxLength(10, 'Must be 10 characters')]}/>
            <ReferenceInput fullWidth label="Facility" validate={required()} reference="facility/list" source="facilityId">
                <AutocompleteInput perPage={200} optionText="name"/>
            </ReferenceInput>
            <TextInput fullWidth label="Facility role" source="position" validate={required()}/>
            <TextInput fullWidth label="Invitation message" source="invitationMessage" validate={[required(), maxLength(500, 'Must be less than 500 characters')]}/>
        </SimpleForm>
    </Create>
);
