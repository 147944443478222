import React from 'react';
import {
    TextField,
    Show, useRecordContext
} from 'react-admin';
import { useResourceContext } from 'ra-core';
import {makeStyles} from "@material-ui/core/styles";
import BackButton from "../../../Component/Layouts/Button/BackButton";

const PostShowActions = () => (
    <div></div>
);

const WithProps = ({children, ...props}) => children(props);

const RenderValue = () => {
    const record = useRecordContext();
    const sss = useRecordContext();

    if(!record){
        return null;
    }

    return (<div style={{margin: '0 25rem', marginTop: '4rem', marginBottom: '15%'}}>
                <h3>Admin has been invited!</h3>
                <div style={{margin: '1rem 0'}}>Admin invitation has been sent to provided phone number: {record.phone}</div>
                <div style={{fontWeight: '600', fontSize: '17px', marginBottom: '0.3rem', marginTop: '0.1rem'}}>Invitation message:</div>

                <div style={{background: '#F0F5FE', display: 'flex'}}>
                    <div style={{width: '95%', padding: '2% 1%'}}>
                        {record.invitationMessage}. Follow the link to get started
                        <div id='jsLinkCopy'
                             style={{color: '#07a', marginTop: '0.4rem', wordBreak: 'break-all'}}>{record.link}</div>
                    </div>
                    <div style={{width: '5%', display: 'inline-flex', alignItems: 'center'}}>
                        <svg
                            width="19" height="24"
                            viewBox="0 0 19 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{cursor: 'pointer'}}
                            onClick={() => {

                                let input = document.createElement('input');
                                input.setAttribute('value', record.link);
                                document.body.appendChild(input);
                                input.select();
                                document.execCommand('copy');
                                document.body.removeChild(input);

                                alert('Url copied!');
                            }}
                        >
                            <path
                                d="M16.9844 21V6.98438H6.01562V21H16.9844ZM16.9844 5.01562C17.5156 5.01562 17.9844 5.21875 18.3906 5.625C18.7969 6 19 6.45313 19 6.98438V21C19 21.5312 18.7969 22 18.3906 22.4062C17.9844 22.8125 17.5156 23.0156 16.9844 23.0156H6.01562C5.48438 23.0156 5.01562 22.8125 4.60938 22.4062C4.20312 22 4 21.5312 4 21V6.98438C4 6.45313 4.20312 6 4.60938 5.625C5.01562 5.21875 5.48438 5.01562 6.01562 5.01562H16.9844ZM13.9844 0.984375V3H1.98438V17.0156H0.015625V3C0.015625 2.46875 0.203125 2 0.578125 1.59375C0.984375 1.1875 1.45312 0.984375 1.98438 0.984375H13.9844Z"
                                fill="#4884EF"/>
                        </svg>
                    </div>
                </div>
                <BackButton link="/facility/admin" label={"Back to list"} />
            </div>);
};


export const FacilityAdminShow = ({permissions, ...props}) => {
   /* const record = useRecordContext();*/

    return (<Show {...props} actions={<PostShowActions />} >
                <RenderValue />
            </Show>)
};