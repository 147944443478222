export const type = [
   { id: 'button', name: 'Button' },
    { id: 'input', name: 'Input' }
];






