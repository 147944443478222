import React from 'react';
import {TextField, ShowController, Show, TabbedShowLayout, Tab, SimpleShowLayout, EditButton} from 'react-admin';
import DownloadButton from "../../../../Component/Layouts/Button/DownloadButton";
import ActionsMod from "../../../../Component/Layouts/Button/ActionsMod";
import CrudMod from "../../../../Component/Layouts/Button/CrudMod";
import {makeStyles} from "@material-ui/core/styles";
import {Divider} from "@mui/material";

export const BillerEncounterShow = ({permissions, ...props}) => {
    return (
        <ShowController {...props}>
            {controllerProps =>
                <Show {...props} {...controllerProps} actions={<ActionsMod title={'Billing summary'} />}>
                    <SimpleShowLayout divider={<Divider flexItem />}>
                        <div>
                            {(controllerProps.record && controllerProps.record.humanReadableBilling)
                                ? (<DownloadButton data={controllerProps.record.humanReadableBilling} fileName={"billing.txt"} text={"Download"}/>)
                                : null
                            }
                            <EditButton {...props} label='Edit' sx={{
                                float: "right",
                                padding: '6px 16px',
                                marginTop: '-2px'
                            }}/>
                            <h4>Patient</h4>
                        </div>
                        <TextField label="First Name" source="billing.firstName"/>
                        <TextField fullWidth label="Last Name" source="billing.lastName"/>
                        <TextField fullWidth label="DOB" source="billing.dob"/>
                        <TextField fullWidth label="Address" source="billing.addressEdit" emptyText='-'/>
                        <TextField fullWidth label="Medical record number" source="billing.medicalRecordNumber" emptyText='-'/>
                        <TextField fullWidth label="Provider" source="billing.provider"/>
                        <TextField fullWidth label="Practice" source="billing.practice" emptyText='-'/>
                        <TextField fullWidth label="Facility" source="billing.facility"/>
                        <TextField fullWidth label="Medicare ID" source="billing.medicareID" emptyText='-'/>
                        <TextField fullWidth label="Medicaid ID" source="billing.medicaidId" emptyText='-'/>
                        <h4>Insurance</h4>
                        <TextField fullWidth label="Primary" source="billing.primary" emptyText='-'/>
                        <TextField fullWidth label="Primary ID" source="billing.primaryID" emptyText='-'/>
                        <TextField fullWidth label="Secondary" source="billing.secondary" emptyText='-'/>
                        <TextField fullWidth label="Secondary ID" source="billing.secondaryID" emptyText='-'/>
                        <TextField fullWidth label="Tertiary" source="billing.tertiary" emptyText='-'/>
                        <TextField fullWidth label="Tertiary ID" source="billing.tertiaryID" emptyText='-'/>
                        <h4>Encounter</h4>
                        <TextField fullWidth label="Date of service" source="billing.dos"/>
                        <TextField fullWidth label="Admission date" source="billing.admissionDate" emptyText='-'/>
                        <TextField fullWidth label="Stay type" source="billing.stayType"/>
                        <TextField fullWidth label="Suggested CPT" source="billing.suggestedCpt" emptyText='-'/>
                        <TextField fullWidth label="ICD-10" source="billing.icd" emptyText='-'/>
                        <TextField fullWidth label="Documented time" source="billing.documentedTime"/>
                    </SimpleShowLayout>
                </Show>
            }
        </ShowController>
    );
}