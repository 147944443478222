import * as React from "react";
import { AppBar } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import inMemoryJWT from "../../Auth/inMemoryJwt";
import Logo from "./Logo";

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 2,
    },
    titleImg: {

    },
    adminName: {
        fontSize: '19px',
    }
});

const MyAppBar = props => {
    const adminName = inMemoryJWT.getName();
    const adminEmail = inMemoryJWT.getEmail();

    const classes = useStyles();
    return (
        <AppBar {...props}>
            <span className={classes.spacer} />
            <Logo />
            <span className={classes.spacer} />
        </AppBar>
    );
};

export default MyAppBar;