import React from 'react';
import {Button, Datagrid, EditButton, sanitizeListRestProps, TextField, TopToolbar, usePermissions} from 'react-admin';
import List, {ListMod} from "../../../../App/Component/Redesign/ListMod";
import CreateButtonMod from "../../../Component/Layouts/Button/CreateButton";
import AddEntityButton from "../../../Component/Layouts/Button/AddEntityButton";


// const ListActions = ({basePath, className, ...rest}) => {
//     const {permissions} = usePermissions();
//
//     return (permissions !== 'SUPER_ADMIN' && <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
//         <AddEntityButton pathEdit='visit/problem/list/create' labelVal='Create problem'/>
//     </TopToolbar>);
// };
const ListActions = ({basePath, className, ...rest}) => {
    return (<TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        <CreateButtonMod basePath={basePath} label={'Problem'}/>
    </TopToolbar>);
};
const sort = { field: 'id', order: 'ASC' };

export const VisitProblemList = ({permissions, ...props}) => (
    <ListMod {...props}
             actions={<ListActions/>}
             basePath="/visit/problem/list"
             sort={sort}
        // filters={<Filters/>}
    >
        <Datagrid bulkActionButtons={false}>
            <TextField label='Id' source="id"/>
            <TextField label='Name' source="name"/>
            <TextField label='Icd' source="icd"/>
            <EditButton label={''}/>
        </Datagrid>
    </ListMod>
);

