import React from 'react';
import {TextField, Show, SimpleShowLayout, SelectField, DateField} from 'react-admin';
import {UserStatus, UserCredentials} from "./constant/userConst";

export const NursesShow = ({permissions, ...props}) => (
    <Show {...props}>
        <SimpleShowLayout>
                <TextField label="Id" source="id" sortBy="id" />
                <TextField label="Phone" source="phone"/>
                <TextField label="First/Given Name" source="firstName"/>
                <TextField label="Last/Family Name" source="lastName" />
                <SelectField label="Status" source="status" choices={UserStatus} />
                <SelectField label="Credentials" source="credentials" choices={UserCredentials} />
                <DateField locales="fr-FR" label="Created At" source="created_at"  />
        </SimpleShowLayout>
    </Show>
);
