import React from 'react';
import {
    TextInput,
    Edit,
    SimpleForm,
    SelectInput,
    required,
} from 'react-admin';
import ToolBarButtonModEdit from "../../../Component/Layouts/Button/ToolBarButtonModEdit";
import ActionsMod from "../../../Component/Layouts/Button/ActionsMod";

import {CptCode} from "./constant/CptConst";

const redirect = () => {
    return `/visit/plan/cpt/list/`;
};

const redirectDel = () => (d) => {
    return `/visit/plan/cpt/list/`;
};



const WithProps = ({children, ...props}) => children(props);

export const CptEdit = ({...props}) => {

    return (<Edit actions={<ActionsMod title={'Edit CPT'}/>} {...props} undoable={false}>
        <WithProps>{({record, ...props}) => {
            return <SimpleForm record={record}
                               toolbar={<ToolBarButtonModEdit showDel="true"
                               hrefCancel="/#/visit/plan/cpt/list/"
                               redirectval={redirectDel()}/>}
                               variant="outlined"
                               {...props}
                               redirect={redirect}
                               encType="multipart/form-data">
                <TextInput fullWidth source="name" validate={[required()]}/>
                <TextInput fullWidth source="code" validate={[required()]}/>
            </SimpleForm>
        }}
        </WithProps>
    </Edit>);
};


