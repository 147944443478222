import * as React from "react";
import {CreateButton} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const CreateButtonMod = props => {
    return (
        <CreateButton sx={{
            color: 'white',
            background: '#4884EF',
            padding: '7px 18px',
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: '13px',
            lineHeight: '16px',
            '&:hover': {
                backgroundColor: '#4884EF'
            }
        }}  basePath={props.basePath} label={props.label} />
    );
};

export default CreateButtonMod;