import {Datagrid, TopToolbar, sanitizeListRestProps, TextField, SelectField} from 'react-admin';
import EditButton from "../../../../Component/Layouts/Button/EditButton";
import List from "../../../../../App/Component/Redesign/ListMod";
import React from 'react';
import {type} from "./constant/itemConst";
import Breadcrumb from "../Breadcrumb";
import {DateTimeWithTimeZoneField} from "../../../../Component/Field/DateTimeWithTimeZoneField";

const idSubGroup = () => {
    const urlParams = new URL(document.URL.replace('#', '')).searchParams;
    return urlParams.get('idSubgroup');
}
const getType = () => {
    const urlParams = new URL(document.URL.replace('#', '')).searchParams;
    return urlParams.get('type');
}

const ListActions = ({basePath, className, ...rest}) => {
    return (<TopToolbar sx={{width: '100% !important'}} {...sanitizeListRestProps(rest)}>
        <Breadcrumb idVal={idSubGroup()} type="item" {...rest} />
        <EditButton pathEdit='visit/config/item' rec={{id: idSubGroup()}} params={'&type=' + getType()} />
    </TopToolbar>);
};

export const ItemConfigList = ({permissions, ...props}) => (
    <List {...props}
        actions={<ListActions />}
        filterDefaultValues={{ idSubGroup: idSubGroup() }}
        basePath="visit/config/item"
    >
        <Datagrid bulkActionButtons={false}>
            <TextField label='Name' source="name" sortable={false}  />
            {getType() == 'exam' ? (<SelectField source="type" choices={type}/>) : null}
            <DateTimeWithTimeZoneField fullWidth label="Created" source="created_at"/>
        </Datagrid>
    </List>
);
