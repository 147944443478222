import React from 'react';
import { Datagrid, Filter, TextField, EditButton, TextInput, Toolbar} from 'react-admin';
import ListMod from "../../../Component/Redesign/ListMod";
import CounselorsButton from "./Component/CounselorsButton";
import DateTimeFieldMod from "../../../Component/Redesign/DateTimeFieldMod";
import {DateTimeWithTimeZoneField} from "../../../Component/Field/DateTimeWithTimeZoneField";

const Filters = (props) => (
    <Filter {...props}>
        <TextInput variant="standard" label="Search by facility:" source="facility" alwaysOn />
        <TextInput variant="standard" label="Search by requester:" source="request" alwaysOn />
    </Filter>
);

export const FacilitiesRequestList = ({...props}) => (
    <ListMod {...props}
             filters={<Filters />}
             basePath="/facility/requests"
    >
        <Datagrid bulkActionButtons={false}>
            <TextField label="id" source="id"/>
            <TextField label="Facility" source="name"/>
            <TextField label="Facility address" source="address"/>
            <TextField label="Requested by" source="provider"/>
            <DateTimeWithTimeZoneField fullWidth label="Date" source="date"/>
            <CustomToolbar/>
        </Datagrid>
    </ListMod>
);

const CustomToolbar = ({ ...props}) => {
    return (
        <Toolbar sx={{display: 'flex', justifyContent: 'center', alignItems: 'flex-start', background: 'none'}}  {...props} >
            <CounselorsButton/>
            <EditButton icon={false} label={'Accept'}/>
        </Toolbar>
    );
}
