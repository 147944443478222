import React from "react";
import {TextInput, Edit, SimpleForm, required, SelectInput, number} from 'react-admin'
import ActionsMod from "../../../Component/Layouts/Button/ActionsMod";
import ToolBarButtonModEdit from "../../../Component/Layouts/Button/ToolBarButtonModEdit";
import {FacilityStatus} from "../../Facility/Facility/constant/facilityConst";

export const FacilityRequestEdit = ({...props}) => {
    return (<Edit {...props} successMessage="Facility approved" actions={<ActionsMod title={'Create facility'} />} mutationMode="pessimistic" undoable={false}>
        <SimpleForm toolbar={<ToolBarButtonModEdit />} variant="outlined" encType="multipart/form-data">
            <TextInput fullWidth label="Facility name" source="name" validate={required()}/>
            <TextInput fullWidth label="Address" source="address" validate={required()}/>
            <TextInput fullWidth label="City" source="city" validate={required()}/>
            <TextInput fullWidth label="State" source="state" validate={required()}/>
            <TextInput fullWidth label="Zip" source="zip" validate={[required(), number()]}/>
        </SimpleForm>
    </Edit>);
}