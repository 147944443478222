import React, {Fragment, useState} from 'react';
import {useRefresh, useUpdate, Button, useNotify, Confirm, useRecordContext} from 'react-admin';
const ApproveButton = () => {
    const [open, setOpen] = useState(false);
    const refresh = useRefresh();
    const notify = useNotify();
    const record = useRecordContext();

    const [approve, { loading }] = useUpdate(
        'provider/requests/approve', {id: record.id, data: {}, previousData: record },
        {
                onSuccess: () => {
                    refresh();
                    notify('Provider approved');
                },
        }
    );


    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const handleConfirm = () => {
        approve();
        setOpen(false);
    };


    return record && record.id ? (<Fragment>
        <Button label="Accept" onClick={handleClick} disabled={loading}></Button>
        <Confirm
            isOpen={open}
            loading={loading}
            title="Accept request?"
            content="This user will access this facility."
            onConfirm={handleConfirm}
            onClose={handleDialogClose}
        />
    </Fragment>) : '-';
};

export default ApproveButton;