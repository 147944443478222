import React from 'react';
import {Datagrid, EditButton, sanitizeListRestProps, TextField, TopToolbar} from 'react-admin';
import {ListMod} from "../../../Component/Redesign/ListMod";
import CreateButtonMod from "../../../Component/Layouts/Button/CreateButton";
import {DateTimeWithTimeZoneField} from "../../../Component/Field/DateTimeWithTimeZoneField";

const ListActions = ({basePath, className, ...rest}) => {
    return (<TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        <CreateButtonMod basePath={basePath} label={'CPT'}/>
    </TopToolbar>);
};

export const VisitCptList = ({permissions, ...props}) => (
    <ListMod {...props}
        actions={<ListActions/>}
        basePath="/visit/plan/cpt/list"
        title="CPT list"
    >
        <Datagrid bulkActionButtons={false}>
            <TextField label='Name' source="name"/>
            <TextField label='Code' source="code"/>
            <DateTimeWithTimeZoneField fullWidth label="Created" source="created_at"/>
            <EditButton label={''}/>
        </Datagrid>
    </ListMod>
)
