import React from 'react';
import {
    Datagrid,
    TextField,
    Filter,
    TextInput,
    DateField,
    SelectField,
    TopToolbar,
    sanitizeListRestProps, Button, useDataProvider, ShowButton, SelectInput, useRecordContext
} from 'react-admin';
import {Type, status} from "./constant/const";
import List from "../../../../App/Component/Redesign/ListMod";
import {makeStyles} from "@material-ui/core/styles";
import classnames from "classnames";
import {IdValueFromConst, LabelValueFromConst} from "../../../Component/Handlers/ValueFromConst";
import Chip from "@material-ui/core/Chip";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';


const useStyles = makeStyles({
    logged: { color: 'white', background: '#32A232'},
    sent: { color: 'white', background: '#4884EF'},
    draft: { color: 'white', background: '#7F8DA0' }
});

const StatusField = props => {
    const classes = useStyles();
    const record = useRecordContext();

    let className = classnames({
        [classes.logged]: record[props.source] === IdValueFromConst(status, 'Logged'),
        [classes.draft]: record[props.source] === IdValueFromConst(status, 'Draft'),
        [classes.sent]: record[props.source] === IdValueFromConst(status, 'Sent'),
    })

    return (
        <Chip
            className={className}
            label={LabelValueFromConst(status, record[props.source], record[props.source])}
        />
    );
};

const ListActions = ({basePath, className, ...rest}) => {
    return (<TopToolbar className={className} {...sanitizeListRestProps(rest)}>
{/*
        <AddEntityButton pathEdit='visit/problem/list' labelVal='List problems'/>
*/}
    </TopToolbar>);
};

const DownloadField = () => {
    const dataProvider = useDataProvider();
    const record = useRecordContext();

    const handleConfirm = () => {

        if(record.id){
            dataProvider
                .getOneWithParams('smarttext/pdf', {'id' : record.room_id})
                .then((data) => {
                const link = document.createElement("a");
                link.href = data.data.link;
                link.target = '_blank';
                link.click();
            }).catch(error => {
                alert('Not found pdf');
            });
        }

    };

    return record.status === 'logged' ?
        (  <Button label={'pdf'} onClick={handleConfirm}>
                <ArrowDownwardIcon  />
            </Button>
        ) : null;
}

const ListFilter = (props) => (
    <Filter {...props} >
        <TextInput variant="standard" label="Patient: " source="patient" alwaysOn />
        <TextInput variant="standard" label="Provider: " source="provider" alwaysOn />
        <TextInput variant="standard" label="Facility: " source="facility" alwaysOn />
        <SelectInput variant="standard" label="Status" emptyText={"All"} emptyValue={''}  source="status" choices={status} alwaysOn />
    </Filter>
);

const ShowButtonMod = props => {
    const record = useRecordContext();
    return record ?
        (<ShowButton
            label=''
            to={{
                pathname: "/smarttext/encounters/list/billing/" + (record.billing_id) + '/show',
            }}
            {...props}
        />) : null;
};

export const EncounterList = ({permissions, ...props}) => (

    <List {...props}
          actions={<ListActions />}
          basePath="/smarttext/encounters/list"
          filters={<ListFilter/>}
          perPage={20}
    >
        <Datagrid bulkActionButtons={false}>
            <DateField label='DOS' source="dos" />
            <TextField label='Patient name' source="patient"/>
            <TextField label='Provider' source="provider"/>
            <TextField label='Practice' source="practice"/>
            <TextField label='Facility' source="facility"/>
            <TextField label='CPT' source="cpt"/>
            <TextField label='ICD-10' source="icd"/>
            <StatusField source="status" />
            <DownloadField />
            <ShowButtonMod/>
        </Datagrid>
    </List>
);

