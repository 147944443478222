import React from "react";
import {
    TextInput,
    Edit,
    SimpleForm,
    required,
    SelectInput,
    number,
    AutocompleteInput,
    ReferenceInput
} from 'react-admin'
import {FacilityStatus} from "./constant/facilityConst";
import ActionsMod from "../../../Component/Layouts/Button/ActionsMod";
import ToolBarButtonModEdit from "../../../Component/Layouts/Button/ToolBarButtonModEdit";

const stateFilterQuery = searchText => ({ name: searchText });

export const FacilityEdit = ({...props}) => {
    return (<Edit {...props} actions={<ActionsMod title={'Edit facility'} />} mutationMode="pessimistic">
        <SimpleForm toolbar={<ToolBarButtonModEdit />} variant="outlined" encType="multipart/form-data">
            <TextInput fullWidth label="Name" source="name" validate={required()}/>
            <TextInput fullWidth label="Address" source="address" validate={required()}/>
            <TextInput fullWidth label="City" source="city" validate={required()}/>
            <ReferenceInput fullWidth reference="states/list" source="state">
                <AutocompleteInput label="State" perPage={50} filterToQuery={stateFilterQuery} optionValue="name" optionText="name" validate={required()}/>
            </ReferenceInput>
            <TextInput fullWidth label="Zip" source="zip" validate={[required(), number()]}/>
            <SelectInput fullWidth label="Status" source="status" choices={FacilityStatus}/>
        </SimpleForm>
    </Edit>);
}