import {
    Edit,
    required,
    SimpleForm,
    TextInput,
} from "react-admin";
import React, {useState} from "react";
import ToolBarButtonModEdit from "../../../../Component/Layouts/Button/ToolBarButtonModEdit";
import { useFormState } from 'react-final-form';
import ActionsMod from "../../../../Component/Layouts/Button/ActionsMod";

const redirect = (basePath, id, data) => {
    return `/provider/list/${data.userId}/show/practice`;
};

const redirectDel = (userId) => (d) => {
    return `/provider/list/${userId}/show/practice`;
};

const WithProps = ({children,...props}) => children(props);

export const PracticeEdit = ({...props}) => {
    return (<Edit actions={<ActionsMod title={'Edit practices'} />} {...props} undoable={false}>
        <WithProps>{({record,...props})=> {
            return record.userId ? (<SimpleForm record={record}
                                toolbar={<ToolBarButtonModEdit showDel="true" redirectval={redirectDel(record.userId)} />}
                                variant="outlined"
                                {...props}
                                redirect={redirect}
                                encType="multipart/form-data">
                <TextInput fullWidth label="Name" source="name" validate={required()}  />
                <TextInput fullWidth label="EIN" source="ein" validate={required()} />
                <TextInput fullWidth label="Billing NPI" source="npi" validate={required()}/>
                <TextInput fullWidth label="Address" source="address" validate={required()}/>
            </SimpleForm>) : '';
        }}
        </WithProps>
    </Edit>);
}