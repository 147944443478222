export const ProviderStatus = [
    { id: 10, name: 'Active' },
    { id: 5, name: 'Invited' }
];

export const ProviderListStatus = [
    { id: 10, name: 'Active' },
    { id: 5, name: 'Invited' },
    { id: 3, name: 'Pending' }
];

export const ProviderFacStatus = [
    { id: 10, name: 'Active' },
    { id: 1, name: 'Invited' },
    { id: 2, name: 'Awaiting Approval' },
    { id: 3, name: 'Rejected' },
];

export const EditProviderStatus = [
    { id: 10, name: 'Active' },
    { id: 5, name: 'Invited' },
];

export const ProviderCredentials = [
    { id: 1, name: 'MD' },
    { id: 2, name: 'DO' },
    { id: 3, name: 'MBBS' },
    { id: 4, name: 'MBBCh' },
    { id: 5, name: 'NP' },
    { id: 6, name: 'CNP' },
    { id: 7, name: 'APNP' },
    { id: 8, name: 'PA' },
    { id: 9, name: 'APRN' },
    { id: 10, name: 'APRN-CNP' }
];




