import React from 'react';
import {
    Datagrid,
    TextField,
    EditButton,
    Filter,
    TextInput,
    SelectInput,
    TopToolbar,
    sanitizeListRestProps,
    Button,
    useRecordContext
} from 'react-admin';
import {ProblemStatus, TemplateStatus} from "./constant/ChiefComplaintConst";
import { Link } from 'react-router-dom';
import ListMod from "../../../Component/Redesign/ListMod";
import {makeStyles} from "@material-ui/core/styles";
import classnames from "classnames";
import {IdValueFromConst, LabelValueFromConst} from "../../../Component/Handlers/ValueFromConst";
import Chip from "@material-ui/core/Chip";
import CreateButtonMod from "../../../Component/Layouts/Button/CreateButton";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles({
    active: { color: 'white', background: '#32A232'},
    inActive: { color: 'white', background: '#52303336' },
    templatePublished: { color: 'white', background: '#32A232'},
    templateArchived: { color: 'white', background: '#52303336' },
    tem_button : {
        marginRight: '10px'
    }
});

const StatusProblemField = props => {
    const classes = useStyles();
    const record = useRecordContext();

    let className = classnames({
        [classes.active]: record[props.source] === IdValueFromConst(ProblemStatus, 'Active'),
        [classes.inActive]: record[props.source] === IdValueFromConst(ProblemStatus, 'Not active'),
    })

    return (
        <Chip
            className={className}
            label={LabelValueFromConst(ProblemStatus, record[props.source], '-')}
        />
    );
};

const StatusTemplateField = props => {
    const classes = useStyles();
    const record = useRecordContext();

    let className = classnames({
        [classes.templatePublished]: record[props.source] === IdValueFromConst(TemplateStatus, 'Published'),
        [classes.templateArchived]: record[props.source] === IdValueFromConst(TemplateStatus, 'Archived'),
    })

    return (
        <Chip
            className={className}
            label={LabelValueFromConst(TemplateStatus, record[props.source], '-')}
        />
    );
};

const ProblemFilter = (props) => (
    <Filter {...props} >
        <TextInput variant="standard" label="Problem: " source="searchName" alwaysOn />
        <TextInput variant="standard" label="Template: " source="searchTemplate" alwaysOn />
        <SelectInput variant="standard" label="Status:" emptyText={"All"} emptyValue={''}  source="problemStatus" choices={ProblemStatus} alwaysOn />
        <SelectInput variant="standard" label="Template status:" emptyText={"All"} emptyValue={''}  source="templateStatus" choices={TemplateStatus} alwaysOn />
    </Filter>
);

const ListActions = ({basePath, className, ...rest}) => {
    const classes = useStyles();
    return (<TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        <CreateButtonMod basePath={basePath} label={'Create problem'} />
    </TopToolbar>);
};

const sort = { field: 'problem', order: 'ASC' };


export const ChiefComplaintList = ({permissions, ...props}) => (
    <ListMod {...props}
          actions={<ListActions />}
          filters={<ProblemFilter/>}
          title="chief complaint"
          basePath="/smarttext/chiefcomplaint"
          sort={sort}
    >

        <Datagrid bulkActionButtons={false}>
            <TextField label="Problem" source="problem"/>
            <TextField label="Template" source="template"/>
            <TextField label="ICD-10" source="icd"/>
            <StatusProblemField label="Problem status" source="status" choices={ProblemStatus} />
            <StatusTemplateField label="Template status" source="templateStatus" choices={TemplateStatus}/>
            <EditButton label={''} />
        </Datagrid>
    </ListMod>
);

