import React from 'react';
import {
    TextInput,
    Create,
    SimpleForm,
    required, number, SelectInput, AutocompleteInput, ReferenceInput
} from 'react-admin';
import {FacilityStatus} from "./constant/facilityConst";
import ActionsMod from "../../../Component/Layouts/Button/ActionsMod";
import SubmitToolbar from "../../../Component/Layouts/Button/SubmitToolbarCreate";

const stateFilterQuery = searchText => ({ name: searchText });
export const FacilityCreate = props => (
    <Create {...props} actions={<ActionsMod title={'Create facility'} />}>
        <SimpleForm toolbar={<SubmitToolbar />} redirect="list" label="list" variant="outlined" encType="multipart/form-data">
            <TextInput fullWidth label="Facility name" source="name" validate={required()}/>
            <TextInput fullWidth label="Address" source="address" validate={required()}/>
            <TextInput fullWidth label="City" source="city" validate={required()}/>
            <ReferenceInput fullWidth reference="states/list" source="state">
                <AutocompleteInput label="State" perPage={50} filterToQuery={stateFilterQuery} optionValue="name" optionText="name" validate={required()}/>
            </ReferenceInput>
            <TextInput fullWidth label="Zip" source="zip" validate={[required(), number()]}/>
            <SelectInput fullWidth label="Status" source="status" choices={FacilityStatus}/>
        </SimpleForm>
    </Create>
);

