import React, {useState} from 'react';
import {
    Create, SimpleForm, SelectInput,
    Toolbar,  TextInput, required,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import BackButton from "../../../Component/Layouts/Button/BackButton";

import {CptCode} from "./constant/CptConst";
import ActionsMod from "../../../Component/Layouts/Button/ActionsMod";
import SubmitToolbar from "../../../Component/Layouts/Button/SubmitToolbarCreate";
import ToolBarButtonModCreate from "../../../Component/Layouts/Button/ToolBarButtonModCreate";

const ToolbarMod = props => {
    return (<Toolbar sx={{ background: 'white', float: 'right' }} {...props} >
                <BackButton label={"Back to list"} link="/visit/plan/cpt/list" />
            </Toolbar>)
}

export const CptCreate = props => {
    return (<Create {...props} actions={<ActionsMod title={'Add CPT'} />}>
        <SimpleForm toolbar={<ToolBarButtonModCreate showCancel="true" hrefCancel="/#/visit/plan/cpt/list" textSave="Add"/>}
                    redirect="list" label="list" variant="outlined" encType="multipart/form-data">
            <TextInput fullWidth label="Name" source="name" validate={required()}/>
            <TextInput fullWidth label="Code" source="code" validate={required()}/>
        </SimpleForm>
    </Create>);
};

