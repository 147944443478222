import {
    Datagrid,
    TopToolbar,
    sanitizeListRestProps,
    TextField,
    ShowButton,
    useRecordContext
} from 'react-admin';
import EditButton from "../../../../Component/Layouts/Button/EditButton";
import List from "../../../../../App/Component/Redesign/ListMod";
import React from 'react';
import {DateTimeWithTimeZoneField} from "../../../../Component/Field/DateTimeWithTimeZoneField";

const TypeGroup = () => {
    const urlParams = new URL(document.URL.replace('#', ''));
    return urlParams.pathname.split('/').pop();
}

const ListActions = ({basePath, className, ...rest}) => {

    return (<TopToolbar  {...sanitizeListRestProps(rest)}>
        <EditButton pathEdit={'visit/config/group/'+TypeGroup()+'/'+TypeGroup()} rec={{}} />
    </TopToolbar>);
};

const ShowButtonMod = props => {
    const record = useRecordContext();
    return record ?
            (<ShowButton
                label=''
                to={{
                    pathname: "/visit/config/" + (record.type === "exam" ? 'subgroup' : 'item'),
                    search: (record.type === "exam" ?
                        "idGroup="+record.id :
                        "idSubgroup="+record.idsubgrpoup) + "&type=" + record.type
                }}
                {...props}
            />) : null;
};

export const GroupConfigList = ({permissions, ...props}) => (
    <List {...props}
          actions={<ListActions />}
          filterDefaultValues={{ type: TypeGroup() }}
    >
        <Datagrid bulkActionButtons={false}>
            <TextField label='Name' source="name" sortable={false}  />
            { TypeGroup() === 'exam' ? (<TextField label='Subgroups' source="subgroups" sortable={false} />) : null}
            <TextField label='Items' source="items" sortable={false}  />
            <DateTimeWithTimeZoneField fullWidth label="Created" source="created_at"/>
            <ShowButtonMod />
        </Datagrid>
    </List>
);
