import React from 'react';
import {TextInput, Edit, SimpleForm, SelectInput, required, DateInput, useLocaleState, useLocale} from 'react-admin';
import ActionsMod from "../../Component/Layouts/Button/ActionsMod";
import ToolBarButtonModEdit from "../../Component/Layouts/Button/ToolBarButtonModEdit";
import {Sex} from "./constant/userConst";

export const PatientEdit = props => {

    return (<Edit actions={<ActionsMod title={'Edit patient'} />} {...props} mutationMode="pessimistic">
        <SimpleForm toolbar={<ToolBarButtonModEdit hrefCancel="/#/patients/list"/>} variant="outlined" encType="multipart/form-data">
            <TextInput fullWidth label="First name" source="first_name" validate={[required()]}/>
            <TextInput fullWidth label="Last name" source="last_name" validate={required()}/>
            <DateInput locales={"en-US"} options={{ format: 'DD/MM/YYYY' }}  fullWidth label="DOB" source="dob" validate={required()} />
            <SelectInput fullWidth label="Sex" source="sex" choices={Sex} validate={[required()]}/>
        </SimpleForm>
    </Edit>);
};
