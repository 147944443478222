import {
    Datagrid, EditButton,
    FunctionField,
    ReferenceManyField,
    SelectField,
    Show,
    Tab,
    TabbedShowLayout,
    TextField, useRecordContext
} from "react-admin";
import AddEntityButton from "../../Component/Layouts/Button/AddEntityButton";
import DeleteButtonProvider from "./Component/DeleteButtonProvider";
import DownloadButton from "../../Component/Layouts/Button/DownloadButton";
import React from "react";
import {status} from "./constant/const";
import {makeStyles} from "@material-ui/core/styles";
import classnames from "classnames";
import {IdValueFromConst, LabelValueFromConst} from "../../Component/Handlers/ValueFromConst";
import {ProviderCredentials, ProviderStatus} from "../User/Provider/constant/providerConst";
import Chip from "@material-ui/core/Chip";
import {UserStatus} from "../User/Nurse/constant/userConst";
import DeleteButtonBiller from "./Component/DeleteButtonBiller";
import PaginationMod from "../../Component/Redesign/PaginationMod";
import { Divider } from '@mui/material';

const PostShowActions = () => (
    <div></div>
);

const Download = () => {
    const record = useRecordContext();
    return <DownloadButton data={record.download} fileName={record.name + "_practice_info.txt"} text={"Download"}/>;
}

const useStyles = makeStyles({
    active: { color: 'white', background: '#32A232'},
    invited: { color: 'white', background: '#4884EF' },
    draft: { color: 'white', background: '#7F8DA0' },
    locked: { color: 'white', background: '#303336' }
});

const StatusField = props => {
    const record = useRecordContext();
    const classes = useStyles();

    let className = classnames({
        [classes.active]: record[props.source] === IdValueFromConst(ProviderStatus, 'Active'),
        [classes.invited]: record[props.source] === IdValueFromConst(ProviderStatus, 'Invited'),
        [classes.draft]: record[props.source] === IdValueFromConst(ProviderStatus, 'Draft'),
        [classes.locked]: record[props.source] === IdValueFromConst(ProviderStatus, 'Blocked'),
    })

    return (
        <Chip
            className={className}
            label={LabelValueFromConst(UserStatus, record[props.source], '-')}
        />
    );
};

export const PracticesShow = ({permissions, ...props}) => {
    const classes = useStyles();

    return <Show {...props} actions={<PostShowActions/>}>
        <TabbedShowLayout divider={<Divider flexItem />}>
            <Tab label="Information">
                <TextField label="Name" source="name"/>
                <TextField label="Practice EIN" source="ein"/>
                <TextField label="Billing NPI" source="npi"/>
                <TextField label="Address" source="address"/>
                <SelectField label="Status" source="status" choices={status}/>
                <div>
                    <Download />
                    <EditButton {...props} sx={{float: "right", padding: '6px 16px', marginTop: '-2px' }}/>
                    <DownloadButton data={props.record} fileName={"billing.txt"} text={"Download"}/>
                </div>
            </Tab>
            <Tab label="Providers" path="providers">
                <div>
                    <AddEntityButton pathEdit='practices/providers/create' showLabel="true" labelVal='Add provider' keyPath='practiceId'/>
                    <ReferenceManyField  pagination={<PaginationMod />} reference="practices/providers" target="practiceId" addLabel={true}>
                        <Datagrid bulkActionButtons={false}  >
                            <TextField label="Name" source="name"/>
                            <SelectField label="Credentials" source="credentials" choices={ProviderCredentials} />
                            <TextField label="Facilities" source="facility_count"/>
                            <TextField label="Total logged chats" source="count_log"/>
                            <StatusField source="status" />
                            <DeleteButtonProvider />
                        </Datagrid>
                    </ReferenceManyField>
                </div>
            </Tab>
            <Tab label="Billers" path="billers">
                <div>
                    <AddEntityButton pathEdit='practices/billers/create' showLabel="true" labelVal='Add biller' keyPath='practiceId'/>
                    <ReferenceManyField pagination={<PaginationMod />} reference="practices/billers" target="practiceId" addLabel={false}>
                        <Datagrid bulkActionButtons={false}>
                            <TextField label="Name" source="name"/>
                            <TextField label="Email" source="email"/>
                            <DeleteButtonBiller />
                        </Datagrid>
                    </ReferenceManyField>
                </div>
            </Tab>
        </TabbedShowLayout>
    </Show>
};