import React from 'react';
import {
    TextInput,
    Edit,
    required,
    SimpleForm,
    ArrayInput,
    SimpleFormIterator,
    FormDataConsumer,
    useRefresh, useRedirect,
    SelectInput, maxLength, ReferenceArrayInput
} from 'react-admin';
import ToolBarButtonModEdit from "../../../../Component/Layouts/Button/ToolBarButtonModEdit";
import {type} from "./constant/itemConst";

const urlParams = new URL(document.URL.replace('#', '')).searchParams;

const getType = () => {
    return urlParams.get('type');
}

export const ItemConfigEdit = props => {
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccess = (data) => {
        redirect("/visit/config/item?idSubgroup=" + data.id + '&type=' + getType());
        refresh();
    };

    return (<Edit mutationOptions={{ onSuccess }} mutationMode="pessimistic" {...props} undoable={false}>
                <SimpleForm  toolbar={<ToolBarButtonModEdit />}  redirect={redirect}  variant="outlined" encType="multipart/form-data">
                    <ArrayInput label="List items"  validate={required()} source="items">
                        <SimpleFormIterator inline >
                             <TextInput fullWidth sx={{width: '100%'}} label="Name" source="name" validate={[required(), maxLength(100)]}/>
                             {getType() == 'exam' ? (<SelectInput sx={{width: '20%', padding: '0 1%'}} label="Type" source="type" choices={type} validate={[required()]} />) : null}
                        </SimpleFormIterator>
                    </ArrayInput>
                </SimpleForm>
            </Edit>);
};
