import CustomLayout from './App/Component/Layouts/CustomLayout';
import authProvider from './App/Auth/authProvider';
import {createMuiTheme} from '@material-ui/core/styles';
import {defaultTheme} from "react-admin";
import { Route } from "react-router-dom";
import {Admin, Resource, ListGuesser} from 'react-admin';
import dataProvider from './App/Api/api';
import * as React from "react";
import {FacilityList} from "./App/Pages/Facility/Facility/list";
import {FacilityEdit} from "./App/Pages/Facility/Facility/edit";
import {FacilityShow} from "./App/Pages/Facility/Facility/show";
import {FacilityCreate} from "./App/Pages/Facility/Facility/create";
import {ProviderList} from "./App/Pages/User/Provider/list";
import {ProviderShow} from "./App/Pages/User/Provider/show";
import {ProviderInvite} from "./App/Pages/User/Provider/invite";
import {ProviderEdit} from "./App/Pages/User/Provider/edit";
import {ChiefComplaintCreate} from "./App/Pages/SmartText/ChiefComplaints/create";
import {ChiefComplaintEdit} from "./App/Pages/SmartText/ChiefComplaints/edit";
import {ChiefComplaintList} from "./App/Pages/SmartText/ChiefComplaints/list";
import {NursesList} from "./App/Pages/User/Nurse/list";
import {NursesShow} from "./App/Pages/User/Nurse/show";
import {NursesCreate} from "./App/Pages/User/Nurse/create";
import {NursesEdit} from "./App/Pages/User/Nurse/edit";
import {ProviderRequestList} from "./App/Pages/Requests/Provider/list";
import {AdminsList} from "./App/Pages/Admin/list";
import {AdminsCreate} from "./App/Pages/Admin/create";
import {AdminsEdit} from "./App/Pages/Admin/edit";
import {LegalList} from "./App/Pages/Legal/list";
import {LegalEdit} from "./App/Pages/Legal/edit";
import {LegalShow} from "./App/Pages/Legal/show";
import {PatientEdit} from "./App/Pages/Patients/edit";
import red from '@material-ui/core/colors/red';

import {FacilitiesRequestList} from "./App/Pages/Requests/Facility/list";
import {PracticeCreate} from "./App/Pages/User/Provider/Practice/create";
import {PracticeEdit} from "./App/Pages/User/Provider/Practice/edit";
import {PracticeAdd} from "./App/Pages/User/Provider/Practice/add";
import {FacilityIntegrationEdit} from "./App/Pages/Facility/Integration/edit";
import {FacilityAdmin} from "./App/Pages/Facility/Admin/list";
import {FacilityAdminCreate} from "./App/Pages/Facility/Admin/create";
import {FacilityAdminShow} from "./App/Pages/Facility/Admin/show";
import {FacilityRequestEdit} from "./App/Pages/Requests/Facility/edit";
import {PatientsList} from "./App/Pages/Patients/list";
import {VisitProblemList} from "./App/Pages/Visit/ProblemList/list";
import {ProblemListCreate} from "./App/Pages/Visit/ProblemList/create";
import {GroupConfigList} from "./App/Pages/Visit/Config/Group/list";
import {GroupConfigEdit} from "./App/Pages/Visit/Config/Group/edit";
import {SubGroupConfigList} from "./App/Pages/Visit/Config/SubGroup/list";
import {SubGroupConfigEdit} from "./App/Pages/Visit/Config/SubGroup/edit";
import {ItemConfigList} from "./App/Pages/Visit/Config/Item/list";
import {ItemConfigEdit} from "./App/Pages/Visit/Config/Item/edit";
import {VisitCptList} from "./App/Pages/Visit/CptList/list";
import {VisitShow} from "./App/Pages/Visit/Visit/show";
import {BillingVisitEdit} from "./App/Pages/Visit/Visit/Billing/edit";
import {BillerVisitList} from "./App/Pages/Visit/Visit/Billing/list";
import {VisitList} from "./App/Pages/Visit/Visit/list";
import {BillerEncounterEdit} from "./App/Pages/SmartText/Encounters/Billing/edit";
import {BillerEncounterShow} from "./App/Pages/SmartText/Encounters/Billing/show";
import {EncounterList} from "./App/Pages/SmartText/Encounters/list";
import {CptCreate} from "./App/Pages/Visit/CptList/create";
import {CptEdit} from "./App/Pages/Visit/CptList/edit";
import {ProblemEdit} from "./App/Pages/Visit/ProblemList/edit";
import {PracticesList} from "./App/Pages/Practices/list";
import {PracticesEdit} from "./App/Pages/Practices/edit"
import {PracticesShow} from "./App/Pages/Practices/show"
import {PracticeAddProvider} from "./App/Pages/Practices/addProvider"
import {PracticeAddBiller} from "./App/Pages/Practices/addBiller"
import {NurseFacility} from "./App/Pages/Facility/Facility/addNurse";

const theme = {
    ...defaultTheme,
    palette: {
        primary: {main: '#4884EF'},
        secondary: {main: '#4884EF'},
        error: red,
    },
    sidebar: {
        width: 200,
        closedWidth: 54,
    },
    typography: {
        color: 'white',
        background: '#4884EF',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: '900',
        lineHeight: '1rem',
        letterSpacing: '1.25px',
    },
    components: {
        ...defaultTheme.components,
        MuiTextField: {
            defaultProps: {
                variant: "outlined",
                margin: "dense",
                size: "small"
            }
        },
        RaMenuItemLink: {
            styleOverrides: {
                root: {
                    borderLeft: '3px solid transparent',
                    '&.RaMenuItemLink-active': {
                        borderLeft: '5px solid #4884EF',
                    },
                },
            },
        },
        MuiAutocomplete: {
            defaultProps: {
                sx : {width: '100%'}
            }
        },
        MuiToolbar: {
            styleOverrides: {
                regular: {
                    marginRight: '0px',
                    marginLeft: 'auto'
                },
            }
        },
        MuiFormControl: {
            styleOverrides: {
                marginDense: {
                    width: '100%'
                },
            }
        },
        MuiButton: {
            defaultProps: {
            //    icon: false
            },
            styleOverrides: {
                containedPrimary: {
                    marginRight: '0px',
                    marginLeft: 'auto'
                },
                root: {
                    "&.button-remove": {
                        color: 'red',
                        marginTop: '4%'
                    },
                },
            }
        },
    },
    overrides: {
        MuiToolbar: {
            regular: {
                background: '#fafafa',
            },

        },
        MuiTypography: {
            body: {
                fontWeight: 600,
            },
            'body2': {
                fontWeight: 400,
                fontSize: '1.05rem',
                textAlign: 'center'
            }
        },
        MuiInputBase: {
            input: {}
        },
        MuiFormLabel: {
            root: {}
        },
        MuiMenuItem: {
            root: {
                fontWeight: 600
            },
        },
        MuiDialogContentText: {
            root: {},
        },
        RaLayout: {
            root: {
                '& button[aria-label="Remove"]': {
                    color: 'red',
                    marginTop: '4%'
                },
                '& .fade-enter': {
                    opacity: '1 !important',
                    transform: 'translateX(0) !important'
                }
            }
        },
        RaAppBar: {
            toolbar: {
                '& button[aria-label="Refresh"]': {
                    display: 'none',
                },
                '& button[title="Close menu"]': {
                    display: 'none',
                }
            }
        }
    }
};

const App = () => (

        <Admin theme={theme} dataProvider={dataProvider} authProvider={authProvider} layout={CustomLayout}>
            {permissions => {
                authProvider.checkAuthRoles(permissions);
                return [
                    permissions === 'ADMIN' || permissions === 'SUPER_ADMIN' || permissions ==='BILLER' || permissions ==='FACILITY_OWNER'
                        ? [
                            <Resource name="provider/practice/user_practice" create={PracticeAdd} />,
                            <Resource name="provider/practice" create={PracticeCreate} edit={PracticeEdit} />,
                            <Resource name="provider/requests" list={ProviderRequestList}/>,
                            <Resource name="provider/list" create={ProviderInvite} list={ProviderList}  edit={ProviderEdit}
                                      show={ProviderShow}/>,
                            <Resource name="facility/list/integration" edit={FacilityIntegrationEdit}/>,
                            <Resource name="facility/providers"/>,
                            <Resource name="facility/nurses" list={NurseFacility}/>,
                            <Resource name="facility/list" create={FacilityCreate} list={FacilityList} edit={FacilityEdit}
                                      show={FacilityShow}/>,
                            <Resource name="practices/list" list={PracticesList} create={PracticeCreate} edit={PracticesEdit} show={PracticesShow} />,
                            <Resource name="practices/providers" create={PracticeAddProvider} />,
                            <Resource name="practices/billers" create={PracticeAddBiller} />,
                            <Resource name="facility/requests" list={FacilitiesRequestList} edit={FacilityRequestEdit}/>,
                            <Resource name="facility/admin" list={FacilityAdmin} create={FacilityAdminCreate}
                                      show={FacilityAdminShow}/>,
                            <Resource name="nurses" create={NursesCreate} list={NursesList} edit={NursesEdit}
                                      show={NursesShow}/>,
                            <Resource name="patients/list" list={PatientsList} edit={PatientEdit} />,
                            <Resource name="smarttext/encounters/list/billing" edit={BillerEncounterEdit} show={BillerEncounterShow}/>,
                            <Resource name="smarttext/encounters/list"  list={EncounterList}/>,
                            <Resource name="smarttext/chiefcomplaint" create={ChiefComplaintCreate} list={ChiefComplaintList}
                                      edit={ChiefComplaintEdit}/>,
                            <Resource name="visit/list/billing" edit={BillingVisitEdit}/>,
                            <Resource name="visit/list" list={VisitList} show={VisitShow}/>,
                            <Resource name="visit/plan/cpt/list" list={VisitCptList} create={CptCreate} edit={CptEdit}/>,
                            <Resource name="visit/problem/list" list={VisitProblemList} create={ProblemListCreate} edit={ProblemEdit}/>,
                            <Resource name="visit/config/group/exam" list={GroupConfigList} edit={GroupConfigEdit} />,
                            <Resource name="visit/config/group/ros" list={GroupConfigList} edit={GroupConfigEdit} />,
                            <Resource name="visit/config/subgroup" list={SubGroupConfigList} edit={SubGroupConfigEdit}/>,
                            <Resource name="visit/config/item" list={ItemConfigList} edit={ItemConfigEdit}/>,
                            <Resource name="admins" list={AdminsList} create={AdminsCreate} edit={AdminsEdit}/>,
                            <Resource name="template/list" list={ListGuesser}/>,
                            <Resource name="facility/category/list"/>,
                            <Resource name="legal" list={LegalList} edit={LegalEdit} show={LegalShow}/>

                        ] : []
                ]}
            }
        </Admin>
    );

export default App;

