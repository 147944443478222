import * as React from "react";
import {DeleteButton, SaveButton, Toolbar} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const ActionsMod = ({title}) => {

    return (<h3 sx={{
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontSize: '20px',
        lineHeight: '24px'
    }}>{title}</h3>);
};

export default ActionsMod;