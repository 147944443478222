import React from 'react';
import {TextField, TabbedShowLayout, ShowController, Show, Tab, SelectField, useRecordContext} from 'react-admin';
import {makeStyles} from "@material-ui/core/styles";
import EditButton from "../../../Component/Layouts/Button/EditButton";
import {ReportType, Type, VisitBillingStatus, VisitBillingStatusEdit} from "./constant/const";
import DownloadButton from "../../../Component/Layouts/Button/DownloadButton";
import {Divider} from "@mui/material";

const PostShowActions = () => (<div></div>);

const WithProps = ({children, ...props}) => children(props);

export const VisitShow = ({permissions, ...props}) => {
    const record = useRecordContext();

    return (<ShowController {...props}>
            {controllerProps => <Show {...props} {...controllerProps}>
                <TabbedShowLayout divider={<Divider flexItem />}>
                    {(controllerProps.record && controllerProps.record.information && controllerProps.record.information.status == 'finished') ? (
                        <Tab {...props} label="Billing" path="billing">
                            <div>
                                <EditButton pathEdit='visit/list/billing'/>
                                {(controllerProps.record.humanReadableBilling) ? (
                                    <DownloadButton data={controllerProps.record.humanReadableBilling} fileName={"billing.txt"} text={"Download"}/>) : null}
                                <h4>Patient information</h4>
                            </div>
                            <TextField label="First Name" source="billing.firstName"/>
                            <TextField label="Last Name" source="billing.lastName"/>
                            <TextField label="DOB" source="billing.dob"/>
                            <TextField label="Address" source="billing.address"/>
                            <TextField label="City" source="billing.city"/>
                            <TextField label="State" source="billing.state"/>
                            <TextField label="Zip" source="billing.zip"/>
                            <h4>Claim Information</h4>
                            <TextField label="DOS" source="billing.dateService"/>
                            <TextField label="Provider" source="billing.provider"/>
                            <TextField label="Facility" source="billing.facility"/>
                            <TextField label="Practice" source="information.practiceName" emptyText={'-'}/>
                            <TextField label="Admission Date" source="billing.admissionDate" emptyText={'-'}/>
                            <TextField label="CPT" source="billing.cpt" emptyText={'-'} />
                            <TextField label="ICD-10" source="billing.icd"/>
                            <TextField label="Type of Care" source="billing.type"/>
                            <TextField label="Report Type" source="billing.reportType"/>
                            <h4>Insurance Information</h4>
                            <TextField label="Primary Name" source="billing.primary" emptyText="-"/>
                            <TextField label="Primary ID" source="billing.primaryID" emptyText="-"/>
                            <TextField label="Secondary Name" source="billing.secondary" emptyText="-"/>
                            <TextField label="Secondary ID" source="billing.secondaryID" emptyText="-"/>
                            <TextField label="Tertiary Name" source="billing.tertiary" emptyText="-"/>
                            <TextField label="Tertiary ID" source="billing.tertiaryID" emptyText="-"/>
                            <TextField label="Medicare ID" source="billing.medicareId" emptyText="-"/>
                            <TextField label="Medicaid ID" source="billing.medicaidId" emptyText="-"/>
                            <h4>Billing details</h4>
                            <SelectField label="Billed" source="billing.isBilled" choices={VisitBillingStatusEdit}/>
                        </Tab>) : null}
                </ TabbedShowLayout>
            </ Show>}
        </ ShowController>);
}