import React from 'react';
import {Datagrid, TextField, TopToolbar, sanitizeListRestProps, useRecordContext} from 'react-admin';
import ListMod from "../../Component/Redesign/ListMod";
import CreateButtonMod from "../../Component/Layouts/Button/CreateButton";
import CrudMod from "../../Component/Layouts/Button/CrudMod";
import {LabelValueFromConst} from "../../Component/Handlers/ValueFromConst";
import {AdminRole} from "./constant/AdminsConst";

const ListActions = ({basePath, className, ...rest}) => {
    return (<TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        <CreateButtonMod basePath={basePath} label={'Add user'} />
    </TopToolbar>);
};

const RoleField = props => {
    const record = useRecordContext();
    if (record[props.source] !== undefined) {
        return LabelValueFromConst(AdminRole, record[props.source].replace(/[^A-Z^-a-z]/g, "", ''));
    }

    return LabelValueFromConst(AdminRole, record[props.source]);
};

export const AdminsList = ({permissions, ...props}) => (
    <ListMod {...props}
             actions={<ListActions/>}
             title="User management"
             basePath="/admins"
    >
        <Datagrid bulkActionButtons={false}>
            <TextField source="id" sortable={false}/>
            <TextField label="Name" source="name" sortable={false}/>
            <RoleField label="Role" source="role" sortable={false}/>
            <CrudMod edit="true"/>
        </Datagrid>

    </ListMod>
);