import React, {useCallback}  from 'react';
import {
    TextInput,
    Create,
    SimpleForm,
    required,
    Toolbar,
    SaveButton,
    ArrayInput,
    SimpleFormIterator, ReferenceInput, AutocompleteInput, useRedirect
} from 'react-admin';
import { useForm } from 'react-final-form';
import ActionsMod from "../../Component/Layouts/Button/ActionsMod";
import SubmitToolbar from "../../Component/Layouts/Button/SubmitToolbarCreate";
import {makeStyles} from "@material-ui/core/styles";
import {useFormContext} from "react-hook-form";
import ToolBarButtonModCreate from "../../Component/Layouts/Button/ToolBarButtonModCreate";

const useStyles = makeStyles({
    errorHide: {
        '& ul' : {
            '& p' : {
                display: 'none'
            }
        }
    },
    facilityTitle: {
        margin: 0
    }
});



export const PracticeAddProvider = props => {
    const urlParams = new URL(document.URL.replace('#', '')).searchParams;
    return (<Create redirect={urlParams.has('practiceId') ? `/practices/list/${urlParams.get('practiceId')}/show/providers` : 'list'}  actions={<ActionsMod title={'Add provider'}/>} {...props} >
        <SimpleForm defaultValues={{'practiceId' : urlParams.get('practiceId')}} label="practice"
                    toolbar={<ToolBarButtonModCreate showCancel="true" hrefCancel={`/#/practices/list/${urlParams.get('practiceId')}/show/providers`} textSave="Add"/>}
                    encType="multipart/form-data">
            <ReferenceInput fullWidth label="Search for provider" variant="outlined" reference="provider/list" source="userId" filterToQuery={searchText => ({ name: searchText })}>
                <AutocompleteInput  optionText='name' variant="outlined" />
            </ReferenceInput>
        </SimpleForm>
    </Create>)
};

const SaveButtonMod = ({ handleSubmitWithRedirect, ...props }) => {
    return <Toolbar {...props} ><SaveButton  basePath="provider/practice/add" /></Toolbar>;
};