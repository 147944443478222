import React from 'react';
import {
    TextInput,
    Edit,
    SimpleForm,
    SelectInput,
    required,
    ReferenceInput,
    AutocompleteInput,
    FormDataConsumer,
} from 'react-admin';
import {TemplatesLimit, ProblemStatus} from "./constant/ChiefComplaintConst";
import ActionsMod from "../../../Component/Layouts/Button/ActionsMod";
import ToolBarButtonModEdit from "../../../Component/Layouts/Button/ToolBarButtonModEdit";
import {CustomAutoComplete} from "../../../Component/Field/CustomAutocomplete";

export const ChiefComplaintEdit = () => {
    return (<Edit actions={<ActionsMod title={'Edit problem'} />}>
        <SimpleForm toolbar={<ToolBarButtonModEdit showCancel={true} hrefCancel="/#/smarttext/chiefcomplaint"  />} variant="outlined" encType="multipart/form-data">
            <TextInput fullWidth label="Problem" source="name" validate={[required()]}/>
            <ReferenceInput fullWidth label="Template" source="templateId" reference="template/list" perPage={TemplatesLimit} validate={required()}>
                <AutocompleteInput optionText='name'/>
            </ReferenceInput>
            <FormDataConsumer>
                { ({ formData, ...rest }) => (
                    <CustomAutoComplete
                        fullWidth
                        label="ICD-10 code"
                        icd={ formData.icd }
                        source="icd"
                        filterQuery={'icd'}
                        url={'visit/problem/list/'}
                        validate={ required() }
                        { ...rest }/>
                ) }
            </FormDataConsumer>
            <SelectInput fullWidth label="Problem status" source="status" validate={required()} choices={ProblemStatus} />
        </SimpleForm>
    </Edit>);
};
