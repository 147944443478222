import React, {useCallback}  from 'react';
import {
    Create,
    SimpleForm,
    ReferenceInput, AutocompleteInput
} from 'react-admin';
import ToolBarButtonModCreate from "../../../Component/Layouts/Button/ToolBarButtonModCreate";
import ActionsMod from "../../../Component/Layouts/Button/ActionsMod";

export const NurseFacility = props => {
    const urlParams = new URL(document.URL.replace('#', '')).searchParams;
    return (<Create redirect={urlParams.has('facilityId') ? `/facility/list/${urlParams.get('facilityId')}/show/nurses` : 'list'}  actions={<ActionsMod title={'Add nurse'}/>} {...props} >
        <SimpleForm defaultValues={{'facilityId' : urlParams.get('facilityId')}} label="practice"
                    toolbar={<ToolBarButtonModCreate showCancel="true" hrefCancel={`/#/facility/list/${urlParams.get('facilityId')}/show/nurses`} textSave="Add"/>}
                    encType="multipart/form-data">
            <ReferenceInput fullWidth label="Search for provider" variant="outlined" reference="nurses" source="userId" filter={{ status: 10 }} filterToQuery={searchText => ({ name: searchText })}>
                <AutocompleteInput  optionText='name' variant="outlined" />
            </ReferenceInput>
        </SimpleForm>
    </Create>)
};