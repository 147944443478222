import React from 'react';
import {
    Datagrid,
    TextField,
    Filter,
    TextInput,
    DateField,
    SelectField,
    TopToolbar,
    sanitizeListRestProps, Button, useDataProvider, ShowButton, BooleanField, SelectInput, useRecordContext
} from 'react-admin';
import {Type, VisitBillingStatus, VisitStatus} from "./constant/const";
import List from "../../../../App/Component/Redesign/ListMod";
import {makeStyles} from "@material-ui/core/styles";
import classnames from "classnames";
import {IdValueFromConst, LabelValueFromConst} from "../../../Component/Handlers/ValueFromConst";
import Chip from "@material-ui/core/Chip";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import BillButton from './Component/BillButton';

const useStyles = makeStyles({
    finished: { color: 'white', background: '#32A232'},
    draft: { color: 'white', background: '#7F8DA0' }
});

const StatusField = props => {
    const classes = useStyles();
    const record = useRecordContext();

    let className = classnames({
        [classes.finished]: record[props.source] === IdValueFromConst(VisitStatus, 'Finished'),
        [classes.draft]: record[props.source] === IdValueFromConst(VisitStatus, 'Draft'),
    })

    return (
        <Chip
            className={className}
            label={LabelValueFromConst(VisitStatus, record[props.source], record[props.source])}
        />
    );
};

const ListActions = ({basePath, className, ...rest}) => {
    return (<TopToolbar className={className} {...sanitizeListRestProps(rest)}>
{/*
        <AddEntityButton pathEdit='visit/problem/list' labelVal='List problems'/>
*/}
    </TopToolbar>);
};

const DownloadField = () => {
    const dataProvider = useDataProvider();
    const record = useRecordContext();

    const handleConfirm = () => {

        if(record.id){
            dataProvider
                .getOneWithParams('visit/getPDF', {'id' : record.id})
                .then((data) => {
                    const link = document.createElement("a");
                    link.href = data.data.link;
                    link.target = '_blank';
                    link.click();
                }).catch(error => {
                    alert('Not found pdf');
                });
        }

    };

    return record.status === 'finished' || record.status === 'billed' ?
        (  <Button label={'pdf'} onClick={handleConfirm}>
                <ArrowDownwardIcon  />
            </Button>
        ) : null;
}

const ListFilter = (props) => (
    <Filter {...props} >
        <TextInput variant="standard" label="Patient: " source="patient" alwaysOn />
        <TextInput variant="standard" label="Provider: " source="provider" alwaysOn />
        <TextInput variant="standard" label="Facility: " source="facility" alwaysOn />
        <TextInput variant="standard" label="Practice: " source="practice" alwaysOn />
        <SelectInput variant="standard" label="Status: " source="status" choices={VisitStatus} alwaysOn />
        <SelectInput variant="standard" label="Billing status: " source="billing_status" choices={VisitBillingStatus} alwaysOn />
    </Filter>
);

const ShowButtonMod = props => {
    const record = useRecordContext();

    return record && record.status === "finished" ?
        (<ShowButton
            label=''
            to={{
                pathname: "/visit/list/" + (record.id) + '/show/billing',
            }}
            {...props}
        />) : null;
};

export const VisitList = ({permissions, ...props}) => (

    <List {...props}
          actions={<ListActions />}
          basePath="/visit/list"
          filters={<ListFilter/>}
          perPage={20}
    >
        <Datagrid bulkActionButtons={false}>
            <DateField label='DOS' source="dos" />
            <TextField label='Patient' source="patient"/>
            <TextField label='DOB' source="dob"/>
            <TextField label='Provider' source="provider"/>
            <TextField label='Practice' source="practice"/>
            <TextField label='Facility' source="facility"/>
            <TextField label='CPT' source="cpt"/>
            <TextField label='ICD-10' source="icd"/>
            <TextField label='Type of Care' source="billedtype"/>
            <TextField label='Report Type' source="billedreporttype"/>
            <StatusField source="status" />
            <div>
                <ShowButtonMod />
                <DownloadField />
            </div>
            <BillButton  label='Billed' />
        </Datagrid>
    </List>
);

