import { stringify } from 'qs';
//import decodeJwt from 'jwt-decode';
import inMemoryJWT from './inMemoryJwt';
import {ENV} from "../Const/ProjectEnv";

const apiUrl = ENV.HOST_BACKEND + '/admin';

const ROLES = {
    ADMIN: 'ADMIN',
    SUPER_ADMIN: 'SUPER_ADMIN',
    BILLER: 'BILLER',
    FACILITY_OWNER: 'FACILITY_OWNER'
};

// eslint-disable-next-line
export default {
    // called when the user attempts to log in
    login: ({ username, password}) => {
        const request = new Request(apiUrl + `/login`, {
            method: 'POST',
            body: stringify({
                username: username,
                password: password
            }),
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded'
            })
        });

        return fetch(request)
            .then((response) => {
                if ((response.status < 200 || response.status >= 300) && ![401, 403].includes(response.status)) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(data => {
                if (!data.status && data.errors) {
                    throw new Error(data.errors.message);
                }

               if (data.status) {
                  // const decodedToken = decodeJwt(data.data.token);
                  // const roles = decodedToken.roles[0];

                   return data;
               }
            })
            .then(({ data }) => inMemoryJWT.setToken(data.token, data.refreshToken || data.refresh_token));
    },
    // called when the user clicks on the logout button
    logout: () => {
        inMemoryJWT.ereaseToken();
        return Promise.resolve();
    },
    // called when the API returns an error
    checkError: ({ status, body }) => {

        if (status === 401 || (status === 403 && body.errors.message !== 'Access Denied.')) {
            inMemoryJWT.ereaseToken();
            return Promise.reject();
        }

        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
        return inMemoryJWT.waitForTokenRefresh().then(() => {
            return inMemoryJWT.getToken() ? Promise.resolve() : Promise.reject();
        });
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => {
        return inMemoryJWT.waitForTokenRefresh().then(() => {
            return inMemoryJWT.getToken()
                ? Promise.resolve(inMemoryJWT.getRoles())
                : Promise.reject();
        });
    },
    checkAuthRoles: (permission) => {
        if (!(permission in ROLES)) {
            alert('The user is set to the wrong role!');
            inMemoryJWT.ereaseToken();
            return this.logout();
        }
    }
};