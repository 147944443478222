import React from 'react';
import {
    TextInput,
    Create,
    SimpleForm,
    required
} from 'react-admin';
import ActionsMod from "../../../Component/Layouts/Button/ActionsMod";
import SubmitToolbar from "../../../Component/Layouts/Button/SubmitToolbarCreate";
import ToolBarButtonModCreate from "../../../Component/Layouts/Button/ToolBarButtonModCreate";

export const ProblemListCreate = props => (
    <Create actions={<ActionsMod title={'Create problem'} />} {...props}>
        <SimpleForm toolbar={<ToolBarButtonModCreate showCancel="true" hrefCancel={`/#/visit/problem/list`} textSave="Save"/>}
             redirect="list" label="list" variant="outlined" encType="multipart/form-data">
            <TextInput fullWidth label="Name" source="name" validate={required()}/>
            <TextInput fullWidth label="Icd" source="icd" validate={required()}/>
        </SimpleForm>
    </Create>
);