import React from 'react';
import {
    TextInput,
    Create,
    SimpleForm,
    required,
    SelectInput,
    ReferenceInput,
    AutocompleteInput,
    email,
    number,
    minLength,
    maxLength,
    ArrayInput,
    SimpleFormIterator, TextField
} from 'react-admin';
import {EditProviderStatus, ProviderCredentials} from "./constant/providerConst";
import ActionsMod from "../../../Component/Layouts/Button/ActionsMod";
import SubmitToolbar from "../../../Component/Layouts/Button/SubmitToolbarCreate";
import ToolBarButtonModCreate from "../../../Component/Layouts/Button/ToolBarButtonModCreate";

const optionRenderer = choice => choice ? `${choice.name} ${choice.address}` : '';

const validateFac = (values) => {
    if (values.filter((item, index) => index !== values.indexOf(item)).length) {
        return 'Duplicated facilities';
    }

    return undefined
};

export const ProviderInvite = props => {
    return (<Create  {...props} actions={<ActionsMod title={'Provider invitation'} />} >
                <SimpleForm  toolbar={<ToolBarButtonModCreate showCancel="true" hrefCancel="/#/provider/list" textSave="Save and invite"/>} fullWidth redirect="list" label="list" variant="outlined" encType="multipart/form-data">
                            <h4>Profile information</h4>
                            <TextInput fullWidth label="First name" source="firstName" validate={required()}/>
                            <TextInput fullWidth label="Last name" source="lastName" validate={required()}/>
                            <SelectInput fullWidth  source="credentials" validate={required()} choices={ProviderCredentials}/>
                            <TextInput type="email" fullWidth label="Email" source="email" validate={[required(), email()]}/>
                            <TextInput fullWidth label="Mobile phone number" source="phone" validate={[required(),number(), minLength(10, 'Must be 10 characters'), maxLength(10, 'Must be 10 characters')]}/>
                            <TextInput fullWidth label="Rendering NPI" source="npi" validate={required()}/>
                            <SelectInput fullWidth source="Status" source="status" validate={required()} choices={EditProviderStatus}/>
                            <h4 style={{margin: 0 }} >Facility</h4>
                            <ArrayInput label="" validate={[required(), validateFac]} defaultValue={[{ facility: "" }]}  sx={{'& ul' : {'& p' : {display: 'none'}}}} source="facility">
                                <SimpleFormIterator disableReordering>
                                    <ReferenceInput  fullWidth label="Search by facility name or address" perPage={200} reference="facility/list" filterToQuery={searchText => ({ facilityAddress: searchText })}>
                                        <AutocompleteInput optionText={optionRenderer} />
                                    </ReferenceInput>
                                </SimpleFormIterator>
                            </ArrayInput>
                </SimpleForm>
            </Create>);
}

