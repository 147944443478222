import React, {useCallback}  from 'react';
import {
    TextInput,
    Create,
    SimpleForm,
    required,
    Toolbar,
    SaveButton,
    ArrayInput,
    SimpleFormIterator, ReferenceInput, AutocompleteInput, useRedirect
} from 'react-admin';
import { useForm } from 'react-final-form';
import ActionsMod from "../../../../Component/Layouts/Button/ActionsMod";
import SubmitToolbar from "../../../../Component/Layouts/Button/SubmitToolbarCreate";
import {makeStyles} from "@material-ui/core/styles";
import {useFormContext} from "react-hook-form";

export const PracticeAdd = props => {
    const urlParams = new URL(document.URL.replace('#', '')).searchParams;

    return (<Create redirect={urlParams.has('providerId') ? `/provider/list/${urlParams.get('providerId')}/show/practice` : 'list'}  actions={<ActionsMod title={'Add practice'}/>} {...props} >
        <SimpleForm defaultValues={{'userId' : urlParams.get('providerId')}} label="practice" toolbar={<SubmitToolbar saveButton={<SaveButtonMod/>}/>} variant="outlined" encType="multipart/form-data">
            <ReferenceInput fullWidth label="Search for practice" reference="provider/practice" source="practiceId">
                <AutocompleteInput optionText='name'  />
            </ReferenceInput>
        </SimpleForm>
    </Create>)
};

const SaveButtonMod = ({ ...props }) => {
    return <Toolbar {...props} ><SaveButton basePath="provider/practice/add" /></Toolbar>;
};