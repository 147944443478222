import React from "react";
import {
    Datagrid,
    TextField,
    TopToolbar,
    sanitizeListRestProps, Filter, TextInput, SelectInput, SelectField, ShowButton, Toolbar, useRecordContext
} from 'react-admin';
import ListMod from "../../../Component/Redesign/ListMod";
import CreateButtonMod from "../../../Component/Layouts/Button/CreateButton";
import {adminStatus, UserCredentials} from "../Admin/constant/facilityConst";
import {makeStyles} from "@material-ui/core/styles";
import classnames from "classnames";
import {IdValueFromConst, LabelValueFromConst} from "../../../Component/Handlers/ValueFromConst";
import Chip from "@material-ui/core/Chip";
import ModalButton from "../../../Component/Layouts/Button/ModalButton";
import DemoteModalButton from "../../../Component/Layouts/Button/DemoteModalButton";
import {VisitStatus} from "../../Visit/Visit/constant/const";

const useStyles = makeStyles({
    active: { color: 'white', background: '#4884EF'},
    invited: { color: 'white', background: '#7F8DA0' }
});

const StatusField = props => {
    const classes = useStyles();
    const record = useRecordContext();

    let className = classnames({
        [classes.active]: record[props.source] === IdValueFromConst(adminStatus, 'Active'),
        [classes.invited]: record[props.source] === IdValueFromConst(adminStatus, 'Invited'),
    })

    return (
        <Chip
            className={className}
            label={LabelValueFromConst(adminStatus, record[props.source], '-')}
        />
    );
};

const ListActions = ({basePath, className, ...rest}) => {
    return (<TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        <CreateButtonMod basePath={basePath} label={'Invite admin'} />
    </TopToolbar>);
};

const ActionBar = () => {
    const record = useRecordContext();

    return record.status === IdValueFromConst(adminStatus, 'Active') ?
            (<ModalButton
                title={'Demote to nurse'}
                modContent={'This user will be promoted to user'}
                modTitle={'Promote to nurse user?'}
                path={'nurses/change-role'}
                successMes={'Demoted'}
                params={{role: 'common'}}
            />) :
            (<DemoteModalButton
                modContent={'This user will lose access to this facility.'}
                modTitle={'Revoke invitation?'}
                path={'nurses/facility'}
                image={false}
                successMes={'Revoked'}
                params={{}}
            />);
};

const Filters = (props) => (
    <Filter {...props}>
        <TextInput variant="standard" label="Name:" source="name" alwaysOn />
        <TextInput variant="standard" label="Facility:" source="facility" alwaysOn />
        <SelectInput variant="standard" label="Credentials:" source="credentials" choices={UserCredentials} alwaysOn />
        <SelectInput variant="standard" label="Status:" source="status" emptyText={"All"} emptyValue={''}  choices={adminStatus} alwaysOn />
    </Filter>
);

const ShowButtonMod = props => {
    const record = useRecordContext();

    return record && record.status === 1 ?
        (<ShowButton
            label=''
            {...props}
        />) : null;
};



export const FacilityAdmin = ({permissions, ...props}) => {

    return (<ListMod {...props}
                     actions={<ListActions/>}
                     basePath="/facility/admin"
                     filters={<Filters/>}>
        <Datagrid bulkActionButtons={false}>
            <TextField label="Id" source="id"/>
            <TextField label="Name" source="name"/>
            <SelectField source="credentials" choices={UserCredentials}/>
            <TextField label="Facility" source="facility"/>
            <StatusField source="status"/>
            <Toolbar sx={{display: 'flex', justifyContent: 'left', alignItems: 'flex-start', background: 'none'}}>
                <ActionBar />
            </Toolbar>
            <ShowButtonMod label=''></ShowButtonMod>

        </Datagrid>
    </ListMod>);
}



