export const UserStatus = [
   { id: 10, name: 'Active' },
    { id: 5, name: 'Invited' },
    { id: 2, name: 'Pending registration' }
];

export const UserCredentials = [
    { id: 11, name: 'RN' },
    { id: 12, name: 'DNP.' },
    { id: 13, name: 'LPN' },
    { id: 14, name: 'CNA' },
    { id: 15, name: 'CCC-SLP' },
    { id: 16, name: 'CF' },
    { id: 17, name: 'RD' },
    { id: 18, name: 'RDN' },
    { id: 19, name: 'LD' },
    { id: 20, name: 'OTR/L' },
    { id: 21, name: 'OTD' },
    { id: 22, name: 'COTA' },
    { id: 23, name: 'PT' },
    { id: 24, name: 'None' },
];

export const UserRoles = [
    { id: 1, name: 'NURSE' },
    { id: 2, name: 'PROVIDER' }
];








