import React from "react";
import {
    Datagrid,
    TextField,
    TopToolbar,
    sanitizeListRestProps, Filter, TextInput, SelectInput, usePermissions, useRecordContext
} from 'react-admin';
import ListMod from "../../../Component/Redesign/ListMod";
import CreateButtonMod from "../../../Component/Layouts/Button/CreateButton";
import {FacilityStatus} from "../Facility/constant/facilityConst";
import {makeStyles} from "@material-ui/core/styles";
import classnames from "classnames";
import {IdValueFromConst, LabelValueFromConst} from "../../../Component/Handlers/ValueFromConst";
import Chip from "@material-ui/core/Chip";
import CrudMod from "../../../Component/Layouts/Button/CrudMod";

const useStyles = makeStyles({
    active: { color: 'white', background: '#32A232'},
    deleted: { color: 'white', background: '#52303336' }
});

const StatusField = props => {
    const classes = useStyles();
    const record = useRecordContext();

    let className = classnames({
        [classes.active]: record[props.source] === IdValueFromConst(FacilityStatus, 'Active'),
        [classes.deleted]: record[props.source] === IdValueFromConst(FacilityStatus, 'Inactive'),
    })

    return (
        <Chip
            className={className}
            label={LabelValueFromConst(FacilityStatus, record[props.source], '-')}
        />
    );
};

const ListActions = ({basePath, className, ...rest}) => {
    const {permissions} = usePermissions();

    return (permissions !== "FACILITY_OWNER") ? (<TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        <CreateButtonMod basePath={basePath} label={'Add facility'} />
    </TopToolbar>) : null;
};

const Filters = (props) => (
    <Filter {...props}>
        <TextInput variant="standard" label="Search by name:" source="searchWord" alwaysOn />
        <SelectInput variant="standard" label="Status" source="status" emptyText={"All"} emptyValue={''}   choices={FacilityStatus} alwaysOn emptyText={'All'} />
    </Filter>
);

export const FacilityList = ({permissions, ...props}) => (
    <ListMod {...props}
             actions={<ListActions />}
             title="List of facilities"
             basePath="/facility/list"
             filters={<Filters />}
    >
        <Datagrid bulkActionButtons={false}>

            <TextField label="Facility" source="name" sortBy="name"/>
            <TextField label="State" source="address" sortBy="address"/>
            <TextField label="Active members" source="count" sortBy="count"/>
            <StatusField source="status" />
            <CrudMod show="true" edit="true" />
        </Datagrid>
    </ListMod>
);