import * as React from "react";
import {Button, DeleteButton, SaveButton, Toolbar} from 'react-admin';

const ToolBarButtonModEdit = props => {
    let showDelete = props.showDel || false;
    let showCancel = props.showCancel || false;
    let hrefCancel = props.hrefCancel || '';
    let textSave = props.textSave || 'Save';
    let deleteConfirmTitle = props.deleteConfirmTitle || '';
    let deleteConfirmContent = props.deleteConfirmContent || '';

    return (<Toolbar {...props} sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    backgroundColor: '#ffffff',
                    '& svg': {
                        display: 'none'
                    },
                    '& button[aria-label="Save"]': {
                        padding: '6px 30px',
                        borderRadius: '4px'
                    },
                }}>
                {showDelete ?
                    (<DeleteButton redirect={props.redirectval} confirmTitle={deleteConfirmTitle} confirmContent={deleteConfirmContent} undoable={false} sx={{
                        border: '1px solid #f44336',
                        borderRadius: '4px',
                        padding: '6px 16px',
                        marginRight: 'auto'
                    }}  />) : <div></div>
                }
                {props.hrefCancel  ?
                    (<Button label='Cancel' href={props.hrefCancel}  undoable={false} sx={{
                        border: '1px solid #4884EF',
                        borderRadius: '4px',
                        padding: '6px 16px',
                        marginLeft: 'auto',
                        marginRight: '20px'
                    }} />) : <div></div>
                }
                <SaveButton sx={{
                    padding: '6px 30px',
                    marginLeft: 0,
                    borderRadius: '4px'
                }} label={props.textSave}/>
            </Toolbar>);
};

export default ToolBarButtonModEdit;