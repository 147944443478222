import React, {useState} from 'react';
import {
    Create, SimpleForm, SelectInput, ReferenceInput,
    ReferenceArrayInput, FormDataConsumer,
    Toolbar, useDataProvider, AutocompleteInput, required
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import BackButton from "../../../Component/Layouts/Button/BackButton";
import { useFormContext } from 'react-hook-form';

const ToolbarMod = props => {
    return (<Toolbar sx={{ background: 'white', float: 'right' }} {...props} >
        <BackButton label={"Back to list"} link="/nurses" />
    </Toolbar>)
}

const FacilityVal = () => {
    const { setValue } = useFormContext();

    return (<ReferenceInput fullWidth label="Facility" reference="facility/list" source="facility">
        <AutocompleteInput perPage={200} onChange={(e) => {setValue('user', '')}} optionText="name"/>
    </ReferenceInput>)
}

export const NursesCreate = props => {
    const dataProvider = useDataProvider();
    const  [valCode, setValCode] = useState({});


    return (<Create {...props}>
        <SimpleForm toolbar={<ToolbarMod />} sx={{ margin: '0 18rem' }} margin="dense" redirect="list" label="list" variant="outlined">
            <FacilityVal />

            <FormDataConsumer fullWidth>
                {({formData, ...rest}) => {
                    return formData.facility ? (
                        <ReferenceInput      fullWidth
                                             filter={{ facilityId: formData.facility || null}}
                                             label="Invited by"
                                             reference="provider/list"
                                             perPage={2000}
                                             source="user">
                            <AutocompleteInput onChange={() => {setValCode({});}} variant="outlined" disabled={!formData.facility}  optionText="name"/>
                        </ReferenceInput>) : null;

                }}
            </FormDataConsumer>

            <FormDataConsumer fullWidth>
                {({formData, ...rest}) => {

                    if (formData.facility && formData.user) {
                        if(!valCode.link) {
                            dataProvider
                                .getOneWithParams('nurses', {
                                    'id' : 'inviteCode',
                                    'facilityId' : formData.facility,
                                    'userId' : formData.user
                                })
                                .then((data) => {
                                    setValCode({
                                        inviteCode: data.data.inviteCode,
                                        link: data.data.link,
                                        cred: data.data.cred,
                                        user: data.data.user
                                    });
                                });
                        }

                        return valCode.inviteCode ?
                            (<div>
                                <div>
                                    <div style={{fontWeight: '600', fontSize: '17px', marginBottom: '0.3rem', marginTop: '0.1rem'}}>Facility code:</div>
                                    <div>{valCode.inviteCode}</div>
                                </div>
                                <div>
                                    <div style={{fontWeight: '600', fontSize: '17px', marginBottom: '0.3rem', marginTop: '0.1rem'}}>Invitation message:</div>
                                    <div style={{background: '#F0F5FE', display: 'flex'}}>
                                        <div style={{width: '95%', padding: '2% 1%'}}>
                                            You have been invited to start using Medlog by {valCode.user}, {valCode.cred}. Follow the link to start using Medlog for HIPAA-compliant messaging. Facility code: {valCode.inviteCode}.
                                            <div id='jsLinkCopy' style={{ color: '#07a', marginTop: '0.4rem', wordBreak: 'break-all' }}>{valCode.link}</div>
                                        </div>
                                        <div style={{width: '5%', display: 'inline-flex', alignItems: 'center' }}>
                                            <svg
                                                width="19" height="24"
                                                viewBox="0 0 19 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                style={{cursor: 'pointer'}}
                                                onClick={() => {

                                                    let input = document.createElement('input');
                                                    input.setAttribute('value', valCode.link);
                                                    document.body.appendChild(input);
                                                    input.select();
                                                    document.execCommand('copy');
                                                    document.body.removeChild(input);

                                                    alert('Url copied!');
                                                }}
                                            >
                                                <path d="M16.9844 21V6.98438H6.01562V21H16.9844ZM16.9844 5.01562C17.5156 5.01562 17.9844 5.21875 18.3906 5.625C18.7969 6 19 6.45313 19 6.98438V21C19 21.5312 18.7969 22 18.3906 22.4062C17.9844 22.8125 17.5156 23.0156 16.9844 23.0156H6.01562C5.48438 23.0156 5.01562 22.8125 4.60938 22.4062C4.20312 22 4 21.5312 4 21V6.98438C4 6.45313 4.20312 6 4.60938 5.625C5.01562 5.21875 5.48438 5.01562 6.01562 5.01562H16.9844ZM13.9844 0.984375V3H1.98438V17.0156H0.015625V3C0.015625 2.46875 0.203125 2 0.578125 1.59375C0.984375 1.1875 1.45312 0.984375 1.98438 0.984375H13.9844Z" fill="#4884EF"/>
                                            </svg>
                                        </div>
                                    </div>

                                </div>
                            </div>) : null;
                    }
                    return null;
                }}
            </FormDataConsumer>
        </SimpleForm>
    </Create>);
};
