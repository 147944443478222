import React from "react";
import {Edit, SaveButton, SimpleForm, Toolbar, TextInput} from 'react-admin'
import { RichTextInput } from 'ra-input-rich-text';
import ToolBarButtonModEdit from "../../Component/Layouts/Button/ToolBarButtonModEdit";
import ActionsMod from "../../Component/Layouts/Button/ActionsMod";

export const LegalEdit = ({...props}) => {

    return (<Edit {...props}  actions={<ActionsMod title={'Edit legal'} />}  undoable={false}>
        <SimpleForm toolbar={<ToolBarButtonModEdit />} variant="outlined" encType="multipart/form-data">
            <TextInput label="Legal" source="title" validation={{required: true}}/>
            <RichTextInput validation={{required: true}} source="text"/>
        </SimpleForm>
    </Edit>);
}
