import React, {useCallback}  from 'react';
import {TextInput, Edit, SimpleForm, required, Toolbar, SaveButton, SelectInput} from 'react-admin';
import { useForm } from 'react-final-form';
import ActionsMod from "../../Component/Layouts/Button/ActionsMod";
import SubmitToolbar from "../../Component/Layouts/Button/SubmitToolbarCreate";
import {status} from "./constant/const";

export const PracticesEdit = props => (
    <Edit actions={<ActionsMod title={'Edit practice'}/>} {...props} >
        <SimpleForm label="practice"  toolbar={<SubmitToolbar />} variant="outlined" encType="multipart/form-data">
            <TextInput fullWidth label="Name" source="name" validate={required()}  />
            <TextInput fullWidth label="EIN" source="ein" validate={required()} />
            <TextInput fullWidth label="Billing NPI" source="npi" validate={required()}/>
            <TextInput fullWidth label="Address" source="address" validate={required()}/>
            <TextInput fullWidth label="City" source="city" validate={required()}/>
            <TextInput fullWidth label="State" source="state" validate={required()}/>
            <TextInput fullWidth label="ZIP" source="zip" validate={required()}/>
            <SelectInput fullWidth label="Status" source="status" choices={status}/>
        </SimpleForm>
    </Edit>
);