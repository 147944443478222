import {Button, useDataProvider, useListContext, useShowContext} from 'react-admin';
import { Link } from 'react-router-dom';
import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";

const getType = () => {
    const urlParams = new URL(document.URL.replace('#', '')).searchParams;
    return urlParams.get('type');
}

const Breadcrumb = ({ idVal, type, ...props}) => {
    const dataProvider = useDataProvider();
    const {isFetching} = useListContext();
    const  [brad, setBrad] = useState([]);

    if(isFetching && !brad.length){
        dataProvider
            .getOneWithParams('visit/config/breadcrumb', {'id' : idVal, 'type' : type,})
            .then((data) => {setBrad(data.data.result)});
    }

    return (<div style={{flexGrow: 1, marginTop: '0.6%', marginBottom: '0.6%'}}>
                {brad.map((val, i) => {
                    return (i == brad.length-1 ?
                            <span>{val.name}</span> :
                        <span>
                            <a style={{color: '#4884EF'}}
                               href={'#/visit/config/'+val.type
                                   +(val.type == "group" ? ('/'+val.id) : ('?'+val.param+'='+val.id))
                                   +(val.param != 'type' ? '&type=' + getType() : '')
                            }
                            >{val.name}</a>
                            <span style={{margin: '0.6%'}}>/</span>
                        </span>
                            )
                })}
            </div>);
};

export default Breadcrumb;

