import React from 'react';
import {BulkExportButton} from 'react-admin';
import {makeStyles} from "@material-ui/core/styles";
import {exporter} from "../../Handlers/ExportDataToFile";
import {isObject} from "jsonexport/lib/core/helper";

const DownloadButton = ({ data, fileName, text }) => {
    if (isObject(data) && fileName) {
        return (<BulkExportButton
                sx={{
                    float: "right",
                    justifyContent: "right",
                    border: '1px solid #4884EF',
                    borderRadius: '4px',
                    padding: '6px 16px',
                    marginRight: '20px'
                }}
                label={text}
                onClick={() => {
                    exporter(data, fileName)
                }}
            />
        );
    }

    return null;
};

export default DownloadButton;