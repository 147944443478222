export const FacilityStatus = [
   { id: 10, name: 'Active' },
   { id: 0, name: 'Inactive' }
];

export const Facilities = [
 { id: 1, name: 'hosp1', address: 'address1' },
 { id: 2, name: 'hosp2', address: 'address1' },
 { id: 3, name: 'hosp3', address: 'address1' },
 { id: 4, name: 'hosp3', address: 'address1' },
];









