export const status = [
    { id: 'draft', name: 'Draft' },
    { id: 'logged', name: 'Logged' },
    { id: 'sent', name: 'Sent' }
];

export const ReportType = [
    { id: 'history_physical', name: 'History & Physical' },
    { id: 'progress_note', name: 'Progress Note' },
    { id: 'telehealth_note', name: 'Telehealth Note' },
    { id: 'tcm_note', name: 'Transitional Care Management Note' },
];

export const Type = [
    { id: 'long_term_care', name: 'Long Term Care' },
    { id: 'assisted_living', name: 'Assisted Living' },
    { id: 'skilled_nursing', name: 'Skilled Nursing' },
    { id: 'hospice', name: 'Hospice' },
];



