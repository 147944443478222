export const UserRoles = [
    { id: 0, name: 'DELETED' },
    { id: 5, name: 'DRAFT' },
    { id: 10, name: 'ACTIVE' }
];

export const Sex = [
    { id: 'male', name: "Male"},
    { id: 'female', name: "Female"},
    { id: 'other', name: "Other"}
];








