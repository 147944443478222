import {useDataProvider, useInput} from "react-admin";
import React, {useEffect, useMemo, useState} from "react";
import {Box, debounce} from "@material-ui/core";
import {Autocomplete, TextField} from "@mui/material";

export const CustomAutoComplete = ({ url, label, source, filterQuery, validate, ...props }) => {
    const { field } = useInput({ source, validate });
    const [query, setQuery] = useState('')
    const [choices, setChoices] = useState([])
    const dataProvider = useDataProvider()
    const [value, setValue] = useState(field.value)

    useEffect(() => {
        dataProvider.getList(url, {
            pagination: {
                page: 1,
                perPage: 50,
            },
            sort: {
                'column': `${filterQuery}`,
                'direction': 'ASC'
            },
            filter: {
                [`${filterQuery}`]: query
            }
        }).then((res) => {
            setChoices(res.data)
        })
    }, [query])

    const debouncedInputChange = useMemo(
        () => debounce(
            (query, reason) => {
                if(reason === 'reset'){
                    setQuery('')

                    return
                }
                setQuery(query)
            }, 1000),
        []
    )

    return <Autocomplete
        {...field}
        value={value}
        style={{margin: '8px 0 24px'}}
        isOptionEqualToValue={(option, value) => {
            return option[`${filterQuery}`] === value
        }}
        options={choices?.length ? choices : [{ [`${filterQuery}`]: props[`${filterQuery}`] }]}
        getOptionLabel={(option) => `${option[`${filterQuery}`] ? option[`${filterQuery}`] : option}`}
        onInputChange={(e, value, reason) => debouncedInputChange(value, reason)}
        onChange={ (e, value) => {
            setValue(value[`${filterQuery}`])
            return field.onChange(value[`${filterQuery}`])
        } }
        renderOption={
            (props, option) => (
                <Box component="li" {...props} key={option[`${filterQuery}`]}>
                    { option[`${filterQuery}`] }
                </Box>
            )
        }
        renderInput={(params) => <TextField label={label} {...params} />}
    />
}
