import React, {useCallback}  from 'react';
import {TextInput, Create, SimpleForm, required, Toolbar, SaveButton, SelectInput, useRedirect} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import ActionsMod from "../../../../Component/Layouts/Button/ActionsMod";
import SubmitToolbar from "../../../../Component/Layouts/Button/SubmitToolbarCreate";
import {status} from "../../../Practices/constant/const";
import ToolBarButtonModCreate from "../../../../Component/Layouts/Button/ToolBarButtonModCreate";

export const PracticeCreate = props => {
    const urlParams = new URL(document.URL.replace('#', '')).searchParams;
    return (<Create redirect={urlParams.has('providerId') ? `/provider/list/${urlParams.get('providerId')}/show/practice` : 'list'} actions={<ActionsMod title={'Add practice'}/>} {...props} >
                <SimpleForm defaultValues={{'userId' : urlParams.get('providerId')}} label="practice"  toolbar={<ToolBarButtonModCreate showCancel="true" hrefCancel="/#/practices/list" textSave="Add"/>} variant="outlined" encType="multipart/form-data">
                    <TextInput fullWidth label="Practice name" source="name" validate={required()}  />
                    <TextInput fullWidth label="Practice EIN" source="ein" validate={required()} />
                    <TextInput fullWidth label="Billing NPI" source="npi" validate={required()}/>
                    <TextInput fullWidth label="Address" source="address" validate={required()}/>
                    <TextInput fullWidth label="City" source="city" validate={required()}/>
                    <TextInput fullWidth label="State" source="state" validate={required()}/>
                    <TextInput fullWidth label="ZIP" source="zip" validate={required()}/>
                    <SelectInput fullWidth label="Status" source="status" choices={status}/>
                </SimpleForm>
            </Create>)
};