import * as React from "react";
import {DeleteButton, EditButton, SaveButton, ShowButton, Toolbar} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const CrudMod = (props) => {
    const {del, show, edit} = props;

    return (<div style={{textAlign: "center"}}>
                {show ? (<ShowButton {...props} label='' />) : ''}
                {edit ? (<EditButton {...props} label=''/>) : ''}
                {del ? (<DeleteButton  undoable={false} {...props} label='' />) : ''}
            </div>);
};

export default CrudMod;