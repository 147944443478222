import React from 'react';
import {
    TextField,
    Show,
    TabbedShowLayout,
    Tab,
    ReferenceManyField,
    Datagrid,
    SelectField,
    FunctionField,
    useRecordContext
} from 'react-admin';
import AddEntityButton from "../../../Component/Layouts/Button/AddEntityButton";
import {ProviderCredentials, ProviderListStatus, ProviderStatus} from "./constant/providerConst";
import DeleteButton from "./Practice/Component/DeleteButton";
import PaginationMod from "../../../Component/Redesign/PaginationMod";
import {Divider} from "@mui/material";
import {makeStyles} from "@material-ui/core/styles";
import classnames from "classnames";
import {IdValueFromConst, LabelValueFromConst} from "../../../Component/Handlers/ValueFromConst";
import {status} from "../../Practices/constant/const";
import Chip from "@material-ui/core/Chip";

const PostShowActions = () => (
    <div></div>
);

const useStyles = makeStyles({
    active: { color: 'white', background: '#32A232'},
    inactive: { color: 'white', background: '#7F8DA0' },
});

const StatusField = props => {
    const classes = useStyles();
    const record = useRecordContext();

    let className = classnames({
        [classes.active]: record[props.source] === IdValueFromConst(status, 'Active'),
        [classes.inactive]: record[props.source] === IdValueFromConst(status, 'Inactive'),
    })

    return (
        <Chip
            className={className}
            label={LabelValueFromConst(status, record[props.source], record[props.source])}
        />
    );
};


export const ProviderShow = ({permissions, ...props}) => (
    <Show {...props} actions={<PostShowActions />}>
        <TabbedShowLayout divider={<Divider flexItem />}>
            <Tab label="Information">
                <h4>Profile information</h4>
                <TextField label="Id" source="id" sortBy="id" />
                <TextField label="First Name" source="firstName"/>
                <TextField label="Last Name" source="lastName"/>
                <TextField label="Email" source="email"/>
                <TextField label="Phone" source="phone"/>
                <SelectField label="Credentials" source="credentials" choices={ProviderCredentials} />
                <SelectField label="Status" source="status" choices={ProviderListStatus} />
                <FunctionField label="Facility" render={record => `${record.facility ? record.facility.join(', ') : ''}`} />
                <TextField label="Created At" source="created_at"/>
            </Tab>
            <Tab label="Practices" path="practice">
                <div>
                    <AddEntityButton pathEdit='provider/practice/user_practice/create' showLabel="true" labelVal='Add practice' keyPath='providerId'/>
                    <AddEntityButton css={{marginRight: '10px'}} pathEdit='provider/practice/create' showLabel="true" labelVal='Create practice' keyPath='providerId'/>
                    <ReferenceManyField pagination={<PaginationMod />} reference="provider/practice" target="userId"  addLabel={false}>
                        <Datagrid bulkActionButtons={false}>
                            <TextField label="Name" source="name" />
                            <TextField label="EIN" source="ein" />
                            <TextField label="Billing NPI" source="npi" />
                            <TextField label="Address" source="address" />
                            <StatusField source="status" />
                            <DeleteButton />
                        </Datagrid>
                    </ReferenceManyField>
                </div>
            </Tab>
        </TabbedShowLayout>
    </Show>
);
