import React from 'react';
import { Fragment } from 'react';
import { List } from 'react-admin';
import PaginationMod from "./PaginationMod";

const PostBulkActionButtons = props => (
    <Fragment>
    </Fragment>
);

export const ListMod = (props) => {
    const sort = props.sort ? props.sort : { field: 'id', order: 'DESC' };

    return <List {...props}
                 pagination={<PaginationMod />}
                 sort={sort}
                 disableSyncWithLocation={false}
                 exporter={false}
            />;
}

export default ListMod;
