import React from 'react';
import {
    TextInput,
    Create,
    SimpleForm,
    required,
    ReferenceInput,
    AutocompleteInput,
    SelectInput,
    FormDataConsumer
} from 'react-admin';
import {ProblemStatus, TemplatesLimit} from "./constant/ChiefComplaintConst";
import ActionsMod from "../../../Component/Layouts/Button/ActionsMod";
import ToolBarButtonModEdit from "../../../Component/Layouts/Button/ToolBarButtonModEdit";
import {CustomAutoComplete} from "../../../Component/Field/CustomAutocomplete";

export const ChiefComplaintCreate = props => (
    <Create redirect={'list'} actions={<ActionsMod title={'Create problem'} />} {...props}>
        <SimpleForm toolbar={<ToolBarButtonModEdit showCancel={true} hrefCancel="/#/smarttext/chiefcomplaint" />}  redirect="list" label="list" variant="outlined" encType="multipart/form-data" >
            <TextInput fullWidth label="Problem" source="chiefComplaint" validate={required()}/>
            <ReferenceInput fullWidth label="Template" source="templateId" reference="template/list" perPage={TemplatesLimit} validate={required()}>
                <AutocompleteInput optionText='name'  />
            </ReferenceInput>
            <FormDataConsumer>
                { ({ formData, ...rest }) => (
                    <CustomAutoComplete
                        fullWidth
                        label="ICD-10 code"
                        icd={ formData.icd }
                        source="icd"
                        filterQuery={'icd'}
                        url={'visit/problem/list/'}
                        validate={ required() }
                        { ...rest }/>
                ) }
            </FormDataConsumer>
            <SelectInput fullWidth label="Problem status" source="status" validate={required()} choices={ProblemStatus}/>
        </SimpleForm>
    </Create>
);

