export const TemplateStatus = [
    { id: 0, name: 'Draft' },
    { id: 1, name: 'Published' },
    { id: 2, name: 'Archived' },
];

export const TemplatesLimit = 2000;

export const ProblemStatus = [
    { id: 0, name: 'Inactive' },
    { id: 1, name: 'Active' },
];





