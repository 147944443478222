import React from 'react';
import {TextInput, Edit, required, SimpleForm, ArrayInput, SimpleFormIterator, maxLength, useRefresh, useRedirect} from 'react-admin';
import ToolBarButtonModEdit from "../../../../Component/Layouts/Button/ToolBarButtonModEdit";

export const GroupConfigEdit = props => {
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccess = (data) => {
        redirect("/visit/config/group/" + data.id);
        refresh();
    };

    return (<Edit mutationOptions={{ onSuccess }} mutationMode="pessimistic" {...props} undoable={false}>
                <SimpleForm toolbar={<ToolBarButtonModEdit />}  redirect={"" + props.id}  variant="outlined" encType="multipart/form-data">
                    <ArrayInput label="List groups" validate={required()} source="groups">
                        <SimpleFormIterator >
                            <TextInput fullWidth label="Name" source="name" validate={[required(), maxLength(100)]}/>
                        </SimpleFormIterator>
                    </ArrayInput>
                </SimpleForm>
            </Edit>);
};
