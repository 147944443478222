import React, {Fragment, useState} from 'react';
import {useRefresh, useUpdate, Button, useNotify, Confirm, useRecordContext} from 'react-admin';

const ModalButton = ({title, modContent, modTitle, path, successMes, params = {}}) => {
    const [open, setOpen] = useState(false);
    const refresh = useRefresh();
    const notify = useNotify();
    const data = useRecordContext();

    const [reject, {loading}] = useUpdate(path, {id: data ? data.id : '', data: params, previousData: data },
{
            onSuccess: () => {
                notify(successMes);
                refresh()
            },
            onFailure: error => notify('Not updated', 'warning'),
        }
    );


    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const handleConfirm = () => {
        reject();
        setOpen(false);
    };

    return data && data.id ? (<Fragment>
        <Button
            onClick={handleClick}
            label={title}
            title={title}
        >
        </Button>
        <Confirm
            isOpen={open}
            loading={loading}
            title={modTitle}
            content={modContent}
            onConfirm={handleConfirm}
            onClose={handleDialogClose}
        />
    </Fragment>) : '';
};

export default ModalButton;