import React, {Fragment, useState} from 'react';
import {useRefresh, useUpdate, Button, useNotify, Confirm, useRecordContext} from 'react-admin';

const DemoteModalButton = ({ modContent, modTitle, path, successMes, params = {}, image = true}) => {
    const [open, setOpen] = useState(false);
    const refresh = useRefresh();
    const notify = useNotify();
    const data = useRecordContext();

    const [reject, {loading}] = useUpdate(path, {id: data ? data.id : '', data: params, previousData: data }, {
            onSuccess: () => {
                notify(successMes);
                refresh()
            },
            onFailure: error => notify('Facility not updated', 'warning'),
        }
    );

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const handleConfirm = () => {
        reject();
        setOpen(false);
        refresh();
    };

    return data && data.id ? (<Fragment>
        <Button onClick={handleClick}>
            {
                image ? (<svg width="18" height="18" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="#F03738"/>
                            <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="black" fill-opacity="0.16"/>
                        </svg>) : 'Revoke invitation'
            }
        </Button>
        <Confirm
            isOpen={open}
            loading={loading}
            title={modTitle}
            content={modContent}
            onConfirm={handleConfirm}
            onClose={handleDialogClose}
        />
    </Fragment>) : '-';
};

export default DemoteModalButton;