import React from "react";
import {
    Datagrid,
    TextField,
    Filter,
    TextInput,
    EditButton,
    SelectField,
} from 'react-admin';
import List from "../../Component/Redesign/ListMod";
import {Sex} from "./constant/userConst";
import {DateTimeWithTimeZoneField} from "../../Component/Field/DateTimeWithTimeZoneField"

const ListFilters = (props) => (
    <Filter {...props}>
        <TextInput variant="standard" label="Search by name:" source="name" alwaysOn />
        <TextInput variant="standard" label="Search by facility:" source="facilityName" alwaysOn />
    </Filter>
);

export const PatientsList = ({permissions, ...props}) => (
    <List {...props}
             basePath="/patients/list"
             filters={<ListFilters/>}
    >
        <Datagrid bulkActionButtons={false}>
            <TextField fullWidth source="id" />
            <TextField fullWidth label="Name" source="full_name" />
            <TextField fullWidth label="DOB" source="dob" sortable={false} />
            <SelectField fullWidth label="Sex" source="sex" sortable={false} choices={Sex}/>
            <TextField fullWidth label="Facility" source="facility_name" />
            <DateTimeWithTimeZoneField fullWidth label="Created At" source="created_at"/>
            <EditButton label={''} />
        </Datagrid>
    </List>
);
