export const VisitStatus = [
    { id: 'draft', name: 'Draft' },
    { id: 'finished', name: 'Finished' },
];

export const VisitBillingStatus = [
    { id: 0, name: "Ready To Bill"},
    { id: 1, name: "Billed"}
];

export const VisitBillingStatusEdit = [
    { id: 0, name: "No"},
    { id: 1, name: "Yes"}
];

export const ReportType = [
    { id: 'history_physical', name: 'History & Physical' },
    { id: 'progress_note', name: 'Progress Note' },
    { id: 'telehealth_note', name: 'Telehealth Note' },
    { id: 'tcm_note', name: 'Transitional Care Management Note' },
];

export const Type = [
    { id: 'long_term_care', name: 'Long Term Care' },
    { id: 'long_term_care_hospice', name: 'Long Term Care (hospice)' },
    { id: 'assisted_living', name: 'Assisted Living' },
    { id: 'assisted_living_hospice', name: 'Assisted Living (hospice)' },
    { id: 'skilled_nursing', name: 'Skilled Nursing' },
    { id: 'skilled_nursing_hospice', name: 'Skilled Nursing (hospice)' },
    { id: 'home_health', name: 'Home Health' },
    { id: 'home_health_hospice', name: 'Home Health (hospice)' },
];



