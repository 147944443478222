import React from 'react';
import {Datagrid, TextField, Filter, TextInput, Toolbar, sanitizeListRestProps} from 'react-admin';
import ListMod from "../../../Component/Redesign/ListMod";
import ApproveButton from "./Component/ApproveButton";
import CounselorsButton from "./Component/CounselorsButton";
import DateTimeFieldMod from "../../../Component/Redesign/DateTimeFieldMod";
import {DateTimeWithTimeZoneField} from "../../../Component/Field/DateTimeWithTimeZoneField";

const ListFilter = (props) => (
    <Filter {...props} >
        <TextInput variant="standard" label="Search by name: " source="providerName" alwaysOn />
        <TextInput variant="standard" label="Search by facility: " source="facilityName" alwaysOn />
    </Filter>
);

export const ProviderRequestList = ({...props}) => {
    return (<ListMod  {...props}
             title="Providers joining facilities requests"
             basePath="/provider/requests"
             filter={{ facNotRequestStatus: true }}
             filters={<ListFilter/>}
    >
        <Datagrid bulkActionButtons={false} width='100%'>
            <TextField source="id" sortBy="id"/>
            <TextField label="Provider" source="username" sortBy="username"/>
            <TextField label="Facility" source="facility" sortBy="facility"/>
            <DateTimeFieldMod label="Date" locales={"en-US"} source="date" showTime sortBy="date"/>
            {/*<DateTimeWithTimeZoneField fullWidth label="Date" source="date" sortBy="date"/>*/}
            <CustomToolbar/>
        </Datagrid>
    </ListMod>
)};

const CustomToolbar =  props => {

    return (<Toolbar sx={{display: 'flex', justifyContent: 'center', alignItems: 'flex-start', background: 'none'}} {...props} >
                <CounselorsButton/>
                <ApproveButton/>
            </Toolbar>);

};
