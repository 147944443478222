import React from 'react';
import {
    TextInput,
    Edit,
    required,
    maxLength,
    SimpleForm,
    ArrayInput,
    SimpleFormIterator,
    FormDataConsumer,
    useRefresh, useRedirect
} from 'react-admin';
import ToolBarButtonModEdit from "../../../../Component/Layouts/Button/ToolBarButtonModEdit";

const urlParams = new URL(document.URL.replace('#', '')).searchParams;

export const SubGroupConfigEdit = props => {
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccess = (data) => {
        redirect("/visit/config/subgroup?idGroup=" + data.id + '&type=' + urlParams.get('type'));
        refresh();
    };

    return (<Edit mutationOptions={{ onSuccess }} mutationMode="pessimistic" {...props} undoable={false}>
                <SimpleForm toolbar={<ToolBarButtonModEdit />}  variant="outlined" encType="multipart/form-data">
                    <ArrayInput label="List subgroup" validate={required()} source="subgroups">
                        <SimpleFormIterator >
                            <TextInput fullWidth label="Name" source="name" validate={[required(), maxLength(100)]}/>
                        </SimpleFormIterator>
                    </ArrayInput>
                </SimpleForm>
            </Edit>);
};
