import {useRecordContext} from 'react-admin';
import {makeStyles} from "@material-ui/core/styles";
import moment from "moment";

const dateFormatter = date => {
    return moment(date).utc(date).local().format('MM/DD/YYYY hh:mm:ss A');
};

const useStyles = makeStyles({
    active: { color: 'white', background: '#32A232'},
    deleted: { color: 'white', background: '#52303336'},
    draft: { color: 'white', background: '#4884EF' },
});

export const DateTimeWithTimeZoneField = props => {
    const classes = useStyles();
    const record = useRecordContext();
    let value = record[props.source];

    return <span class = {classes}> {dateFormatter(value)} </span> ;
};
