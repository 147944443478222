import React from 'react';
import {useRefresh, useUpdate, Button, useRecordContext} from 'react-admin';
import Checkbox from '@material-ui/core/Checkbox';
import {colors} from "@material-ui/core";


const BillButton = ({}) => {
    const refresh = useRefresh();
    const record = useRecordContext();
    const [bill, { loading }] = useUpdate(
        'visit/list/bill',
        {id: record.id, data: {status: 1}, previousData: record },
        { onSuccess: () => { refresh() }}
    );

    const [unbill, { unbillLoading }] = useUpdate('visit/list/unbill', {id: record.id, data: {status: 1}, previousData: record },
        {
            onSuccess: () => { refresh() },
        }
    );
    return record.status == 'finished' ? <Checkbox style={{color: '#4884EF'}} checked={record.is_billed == 1 ? true : false} onClick={() => {return record.is_billed == 1 ? unbill() : bill()} } /> : null
}

export default BillButton;