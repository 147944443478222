import React from 'react';
import {
    TextInput,
    Edit,
    SimpleForm,
    SelectInput,
    required,
} from 'react-admin';
import ToolBarButtonModEdit from "../../../Component/Layouts/Button/ToolBarButtonModEdit";
import ActionsMod from "../../../Component/Layouts/Button/ActionsMod";

const redirect = () => {
    return `/visit/problem/list/`;
};

const redirectDel = () => (d) => {
    return `/visit/problem/list/`;
};



const WithProps = ({children, ...props}) => children(props);

export const ProblemEdit = ({...props}) => {

    return (<Edit   actions={<ActionsMod title={'Edit problem'}/>} {...props} undoable={false} mutationMode="pessimistic">
        <WithProps>{({record, ...props}) => {
            return <SimpleForm record={record}
                               toolbar={<ToolBarButtonModEdit
                               hrefCancel="/#/visit/problem/list/"
                               redirectval={redirectDel()}/>}
                               variant="outlined"
                               {...props}
                               redirect={redirect}
                               encType="multipart/form-data">
                <TextInput fullWidth source="name" validate={[required()]}/>
                <TextInput fullWidth source="icd" validate={[required()]}/>
            </SimpleForm>
        }}
        </WithProps>
    </Edit>);
};


