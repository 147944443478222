import React from 'react';
import {
    TextField,
    Show,
    SelectField,
    TabbedShowLayout,
    Tab, BooleanField, ReferenceManyField, Datagrid, Toolbar, useRecordContext, SelectInput
} from 'react-admin';
import {FacilityStatus} from "./constant/facilityConst";
import EditButton from "../../../Component/Layouts/Button/EditButton";
import {UserCredentials, UserStatus} from "../../User/Nurse/constant/userConst";
import {ProviderCredentials, ProviderFacStatus, ProviderStatus} from "../../User/Provider/constant/providerConst";
import {makeStyles} from "@material-ui/core/styles";
import classnames from "classnames";
import {IdValueFromConst, LabelValueFromConst} from "../../../Component/Handlers/ValueFromConst";
import Chip from "@material-ui/core/Chip";
import AddEntityButton from "../../../Component/Layouts/Button/AddEntityButton";
import DemoteModalButton from "../../../Component/Layouts/Button/DemoteModalButton";
import ModalButton from "../../../Component/Layouts/Button/ModalButton";
import {Divider} from "@mui/material";
import {useParams} from "react-router-dom";
import {adminStatus} from "../Admin/constant/facilityConst";
import {DateTimeWithTimeZoneField} from "../../../Component/Field/DateTimeWithTimeZoneField";

const PostShowActions = () => (
    <div></div>
);

const useStyles = makeStyles({
    active: { color: 'white', background: '#32A232'},
    invited: { color: 'white', background: '#4884EF' },
    awaiting_approval: { color: 'white', background: '#7F8DA0' },
    rejected: { color: 'white', background: '#303336' }
});

const StatusField = props => {
    const classes = useStyles();
    const record = useRecordContext();

    let className = classnames({
        [classes.active]: record[props.source] === IdValueFromConst(ProviderFacStatus, 'Active'),
        [classes.invited]: record[props.source] === IdValueFromConst(ProviderFacStatus, 'Invited'),
        [classes.awaiting_approval]: record[props.source] === IdValueFromConst(ProviderFacStatus, 'Awaiting Approval'),
        [classes.rejected]: record[props.source] === IdValueFromConst(ProviderFacStatus, 'Rejected'),
    })

    return (
        <Chip
            className={className}
            label={LabelValueFromConst(ProviderFacStatus, record[props.source], '-')}
        />
    );
};

const useStylesAdmin = makeStyles({
    active: { color: 'white', background: '#4884EF'},
    invited: { color: 'white', background: '#7F8DA0' }
});

const StatusFieldAdmin = props => {
    const classes = useStylesAdmin();
    const record = useRecordContext();

    let className = classnames({
        [classes.active]: record[props.source] === IdValueFromConst(adminStatus, 'Active'),
        [classes.invited]: record[props.source] === IdValueFromConst(adminStatus, 'Invited'),
    })

    return (
        <Chip
            className={className}
            label={LabelValueFromConst(adminStatus, record[props.source], '-')}
        />
    );
};

export const FacilityShow = ({permissions, ...props}) => {
    const {id} = useParams();

    return (
        <Show {...props} actions={<PostShowActions/>}>
            <TabbedShowLayout divider={<Divider flexItem />}>
                <Tab label="Facility information">
                    <TextField label="Facility name" source="name"/>
                    <TextField label="Address" source="address"/>
                    <TextField label="City" source="city"/>
                    <TextField label="State" source="state"/>
                    <TextField label="ZIP" source="zip"/>
                    <SelectField label="Status" source="status" choices={FacilityStatus}/>
                    <DateTimeWithTimeZoneField fullWidth label="Created At" source="created_at"/>
                </Tab>
                <Tab label="integration" path="integration">
                    <div>
                        <EditButton pathEdit='facility/list/integration'/>
                    </div>
                    <BooleanField label="PointClickCare integration" source="pcc"/>
                    <TextField label="Organization Id" source="pccOrganizationId"/>
                    <TextField label="Facility id" source="pccFacilityId"/>
                    <BooleanField label="Send visit PDFs to PointClickCare" source="sendPdf"/>
                </Tab>
                <Tab label="Nurses" path="nurses">
                    <div>
                        <AddEntityButton pathEdit='facility/nurses' labelVal='Add nurse' keyPath='facilityId'/>
                        <AddEntityButton css={{marginRight: '10px'}} pathEdit='nurses/create' labelVal='Invite nurse' keyPath='facilityId'/>
                        <ReferenceManyField filter={{role: 'common'}} reference="facility/admin" target="facilityId" addLabel={false}>
                            <Datagrid bulkActionButtons={false}>
                                <TextField label='Name' source="name" sortBy="username"/>
                                <SelectField source="credentials" choices={UserCredentials}/>
                                <Toolbar sx={{display: 'flex', justifyContent: 'center', alignItems: 'flex-start', background: 'none'}}>
                                    <ModalButton
                                        title={'Promote to admin'}
                                        modContent={'This user will be promoted to admin'}
                                        modTitle={'Promote to admin user?'}
                                        path={'nurses/change-role'}
                                        successMes={'Promoted'}
                                        params={{facId: id, role: 'admin'}}
                                    />
                                    <DemoteModalButton
                                        modContent={'This user will lose access to this facility.'}
                                        modTitle={'Remove member from facility?'}
                                        path={'nurses/facility'}
                                        successMes={'Nurse removed'}
                                        params={{facId: id}}
                                    />
                                </Toolbar>
                            </Datagrid>
                        </ReferenceManyField>
                    </div>
                </Tab>
                <Tab label="Providers" path="providers">
                    <div>
                        <AddEntityButton  pathEdit='provider/list/create' labelVal='Invite provider' keyPath='facilityId'/>
                        <ReferenceManyField reference="facility/providers" target="facilityId"
                                            addLabel={false}>
                            <Datagrid bulkActionButtons={false}>
                                <TextField label='Name' source="name" sortBy="name"/>
                                <SelectField label="Credentials" source="credentials" choices={ProviderCredentials}/>
                                <StatusField source="status"/>
                                <DemoteModalButton
                                    modContent={'This user will lose access to this facility.'}
                                    modTitle={'Remove member from facility?'}
                                    path={'nurses/facility'}
                                    successMes={'Provider removed'}
                                    params={{facId: id}}
                                />
                            </Datagrid>
                        </ReferenceManyField>
                    </div>
                </Tab>
                <Tab label="Admins" path="admins">
                    <div>
                        <AddEntityButton pathEdit='facility/admin/create' labelVal='Invite admin' keyPath='facilityId'/>
                        <ReferenceManyField reference="facility/admin" target="facilityId" addLabel={false}>
                            <Datagrid bulkActionButtons={false}>
                                <TextField label="Name" source="name"/>
                                <SelectField source="credentials" choices={UserCredentials}/>
                                <StatusFieldAdmin source="status"/>
                                <Toolbar sx={{display: 'flex', justifyContent: 'center', alignItems: 'flex-start', background: 'none'}}>
                                    <ModalButton
                                        title={'Demote to nurse'}
                                        modContent={'This user will be promoted to user'}
                                        modTitle={'Promote to nurse user?'}
                                        path={'nurses/change-role'}
                                        successMes={'Demoted'}
                                        params={{facId: id, role: 'common'}}
                                    />
                                    <DemoteModalButton
                                        modContent={'This user will lose access to this facility.'}
                                        modTitle={'Remove member from facility?'}
                                        path={'nurses/facility'}
                                        successMes={'Admin removed'}
                                        params={{facId: id}}
                                    />
                                </Toolbar>
                            </Datagrid>
                        </ReferenceManyField>
                    </div>
                </Tab>
                <Tab label="Patients" path="patients">
                    <div>
                        <ReferenceManyField reference="patients/list" target="facilityId"
                                            addLabel={false}>
                            <Datagrid bulkActionButtons={false}>
                                <TextField fullWidth label="Name" source="full_name" />
                                <TextField fullWidth label="DOB" source="dob" sortable={false} />
                                <EditButton label={''} pathEdit="patients/list" />
                            </Datagrid>
                        </ReferenceManyField>
                    </div>
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
}
