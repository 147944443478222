import React from 'react';
import {SaveButton, Toolbar} from "react-admin";
import {makeStyles} from "@material-ui/core/styles";

const SubmitToolbar = props => {

   return (<Toolbar {...props} sx={{
               display: 'flex',
               justifyContent: 'flex-end',
               backgroundColor: '#ffffff',
               '& svg': {
                   display: 'none'
               },
               '& button[aria-label="Save"]': {
                   padding: '6px 30px',
                   borderRadius: '4px'
               },
               '& div[role="toolbar"]': {
                   backgroundColor: '#ffffff',
               }
           }} >
                {props.saveButton ? props.saveButton : <SaveButton />}
           </Toolbar>);
};

export default SubmitToolbar;
