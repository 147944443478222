import React from 'react';
import {TextField, Show, SimpleShowLayout, FunctionField} from 'react-admin';
import parse from 'html-react-parser';
import {Divider} from "@mui/material";
import {DateTimeWithTimeZoneField} from "../../Component/Field/DateTimeWithTimeZoneField";

const PostShowActions = () => (
    <div></div>
);

export const LegalShow = ({permissions, ...props}) => (
    <Show {...props} actions={<PostShowActions />}>
        <SimpleShowLayout divider={<Divider flexItem />}>
            <TextField label="Legal" source="title" sortBy="title"/>
            <FunctionField label="Text" render={record => parse(`${record.text}`)}/>;
            <DateTimeWithTimeZoneField fullWidth label="Last update" source="created_at"/>
        </SimpleShowLayout>
    </Show>
);
