import {MenuItemLink, useRefresh} from 'react-admin';
import { usePermissions, Menu } from 'react-admin';
import { useLocation } from 'react-router-dom';
import {Box} from '@material-ui/core';
import MenuItem from "@material-ui/core/MenuItem";
import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {makeStyles} from "@material-ui/core/styles";
import SubMenu from "./SubMenu";

import DomainIcon from '@mui/icons-material/Domain';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import EventNoteIcon from '@mui/icons-material/EventNote';
import BuildIcon from '@mui/icons-material/Build';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AccountBoxIcon from '@mui/icons-material/AccountBox';

const useStyles = makeStyles(
    theme => ({
        icon: {minWidth: theme.spacing(5)},
    }),
    {name: 'RaMenuItemLink'}
);

const CustomMenu = (props) => {
    const [state, setState] = useState({
        menuVisit: false,
        menuVisitConfig: false,
        menuRequest: false,
        menuVisitBiller: false,

    });

 //   const open = useSelector((state) => state.admin.ui.sidebarOpen);
/*    useSelector((state) => state.theme); // force rerender on theme change

    const handleToggle = (menu) => {
        setState(state => ({...state, [menu]: !state[menu]}));
    };
    const classes = useStyles();

    */
    var constructorLink = window.location.origin + "/constructor";
    const {permissions} = usePermissions();
    const { pathname } = useLocation();
/*
    console.log("permissionspermissions");
    console.log(pathname, permissions);
*/

    const refresh = useRefresh();

    return (
        <Menu {...props}>
            {['ADMIN', 'SUPER_ADMIN', 'FACILITY_OWNER'].includes(permissions) &&
                <Menu.Item  to="/facility/list" primaryText="Facilities" leftIcon={<DomainIcon/>}/>
            }

            {['ADMIN', 'SUPER_ADMIN'].includes(permissions) &&
                <SubMenu primaryText="Requests" leftIcon={<NotificationsIcon />} isDropdownOpen={['/facility/requests','/provider/requests'].includes(pathname)} >
                    <Menu.Item to="/facility/requests" primaryText="Facilities" leftIcon={<FormatListBulletedIcon fontSize='small' />}/>
                    <Menu.Item to="/provider/requests" primaryText="Providers" leftIcon={<FormatListBulletedIcon fontSize='small'/>}/>
                </SubMenu>
            }

            {['ADMIN', 'SUPER_ADMIN', 'FACILITY_OWNER'].includes(permissions) &&
                <Menu.Item to="/provider/list" primaryText="Providers" leftIcon={<PeopleOutlineIcon/>}/>
            }
            {['ADMIN', 'SUPER_ADMIN'].includes(permissions) &&
                <Menu.Item to="/practices/list" primaryText="Practices" leftIcon={<PeopleOutlineIcon/>}/>
            }
            {['ADMIN', 'SUPER_ADMIN', 'FACILITY_OWNER'].includes(permissions) &&
                <Menu.Item to="/facility/admin" primaryText="Facility admins" leftIcon={<PeopleOutlineIcon/>}/>
            }
            {['ADMIN', 'SUPER_ADMIN', 'FACILITY_OWNER'].includes(permissions) &&
                <Menu.Item to="/nurses" primaryText="Nurses" leftIcon={<PeopleOutlineIcon/>}/>
            }

            {['SUPER_ADMIN', 'FACILITY_OWNER'].includes(permissions) &&
                <Menu.Item to="/patients/list" primaryText="Patients" leftIcon={<PeopleOutlineIcon/>}/>
            }

            {['SUPER_ADMIN', 'FACILITY_OWNER'].includes(permissions) &&
                <SubMenu primaryText="SmartText" leftIcon={<EventNoteIcon />}>
                    {['SUPER_ADMIN', 'FACILITY_OWNER'].includes(permissions) &&
                        <Menu.Item to="/smarttext/encounters/list" primaryText="Encounters" leftIcon={<FormatListBulletedIcon fontSize='small' />}/>}
                    {['ADMIN', 'SUPER_ADMIN'].includes(permissions) &&
                        <Menu.Item to="/smarttext/chiefcomplaint" primaryText="Problems" leftIcon={<FormatListBulletedIcon fontSize='small' />}/>}
                    {['SUPER_ADMIN'].includes(permissions) &&
                        <Menu.Item
                                   to={constructorLink}
                                   component="a"
                                   target="_blank"
                                   primaryText="Constructor"
                                   href={constructorLink}
                                   leftIcon={<FormatListBulletedIcon fontSize='small' />}/>
                    }
                </SubMenu>
            }


            {['BILLER', 'SUPER_ADMIN', 'FACILITY_OWNER'].includes(permissions) &&
                <SubMenu primaryText="SmartDocs" leftIcon={<EventNoteIcon />} isDropdownOpen={['/visit/list',
                    '/visit/problem/list', '/visit/plan/cpt/list', '/visit/config/group/ros', '/visit/config/group/exam'].includes(pathname)}>
                    {['SUPER_ADMIN', 'BILLER', 'FACILITY_OWNER'].includes(permissions) &&
                        <Menu.Item to={"/visit/list" + (['BILLER'].includes(permissions) ? `?filter=${JSON.stringify({ billing_status: 0 })}` : '')}
                                   primaryText="Visits" leftIcon={<FormatListBulletedIcon fontSize='small' />}/>}
                    {['ADMIN', 'SUPER_ADMIN'].includes(permissions) &&
                        <Menu.Item to="/visit/problem/list" primaryText="Problems" leftIcon={<FormatListBulletedIcon fontSize='small' />}/>}
                    {['ADMIN', 'SUPER_ADMIN'].includes(permissions) &&
                        <Menu.Item to="/visit/plan/cpt/list" primaryText="CPTs" leftIcon={<FormatListBulletedIcon fontSize='small' />}/>}
                    {['ADMIN', 'SUPER_ADMIN'].includes(permissions) &&
                        <SubMenu primaryText="Configure" leftIcon={<BuildIcon/>} isDropdownOpen={['/visit/config/group/ros', '/visit/config/group/exam'].includes(pathname)}>
                            <Menu.Item  dense={false} to="/visit/config/group/ros" primaryText="ROS" leftIcon={<FormatListBulletedIcon fontSize='small'/>}/>
                            <Menu.Item dense={false}  to="/visit/config/group/exam" primaryText="Exam" leftIcon={<FormatListBulletedIcon fontSize='small'/>}/>
                        </SubMenu>
                    }
                </SubMenu>
            }

            {['ADMIN', 'SUPER_ADMIN'].includes(permissions) &&
                <Menu.Item to="/legal" primaryText="Legal" leftIcon={<LiveHelpIcon />}/>
            }
            {['ADMIN', 'SUPER_ADMIN'].includes(permissions) &&
                <Menu.Item to="/admins" primaryText="User management" leftIcon={<AccountBoxIcon />}/>
            }
        </Menu>
    );
}

export default CustomMenu;