import React, {useEffect, useState} from 'react';
import {
    TextInput,
    Edit,
    SimpleForm,
    required,
    SelectInput,
    TopToolbar,
    CreateButton,
    TabbedShowLayout,
    Tab,
    ArrayInput,
    SimpleFormIterator,
    ReferenceInput,
    AutocompleteInput,
    Datagrid,
    TextField,
    EditButton, ArrayField, FieldTitle, email, number, minLength, maxLength, ReferenceArrayInput
} from 'react-admin';

import {EditProviderStatus, ProviderCredentials, ProviderStatus} from "./constant/providerConst";
import List from "../../../Component/Redesign/ListMod";
import ActionsMod from "../../../Component/Layouts/Button/ActionsMod";
import ToolBarButtonModEdit from "../../../Component/Layouts/Button/ToolBarButtonModEdit";
import {makeStyles} from "@material-ui/core/styles";

const optionRenderer = choice => choice ? `${choice.name} ${choice.address}` : '--';

const validate = (values) => {
    const errors = {};

    if (values.facilities.filter((item, index) => index !== values.facilities.indexOf(item)).length) {
        errors.facilities = 'Duplicated facilities';
    }

    return errors
};

export const ProviderEdit = props => {

    return (<Edit actions={<ActionsMod title={'Edit provider'} />} {...props} mutationMode="pessimistic" undoable={false}>
        <SimpleForm validate={validate} toolbar={<ToolBarButtonModEdit />}  variant="outlined"  encType="multipart/form-data">
            <h4>Profile information</h4>
            <TextInput fullWidth label="First name" source="firstName" validate={required()}/>
            <TextInput fullWidth label="Last name" source="lastName" validate={required()}/>
            <SelectInput fullWidth  source="credentials" validate={required()} choices={ProviderCredentials}/>
            <TextInput fullWidth label="Billing NPI" source="npi" validate={required()}/>
            <SelectInput fullWidth source="Status" source="status" validate={required()} choices={EditProviderStatus}/>
            <h4 style={{margin: 0}}>Facility</h4>
            <ArrayInput label="" validate={required()} sx={{'& ul' : {'& p' : { display: 'none'}}}} source="facilities">
                <SimpleFormIterator disableReordering>
                    <ReferenceInput fullWidth label="Search by facility name or address" perPage={200} reference="facility/list" filterToQuery={searchText => ({ facilityAddress: searchText })}>
                        <AutocompleteInput optionText={optionRenderer}/>
                    </ReferenceInput>
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Edit>);
};

