import React from 'react';
import {
    Datagrid,
    TextField,
    SelectField,
    EditButton,
    TopToolbar,
    DeleteButton,
    Filter,
    TextInput,
    SelectInput, sanitizeListRestProps, ShowButton, useRecordContext,
} from 'react-admin';
import {ProviderCredentials, ProviderListStatus, ProviderStatus} from "./constant/providerConst";
import List from "../../../../App/Component/Redesign/ListMod";
import CreateButtonMod from "../../../Component/Layouts/Button/CreateButton";
import {makeStyles} from "@material-ui/core/styles";
import classnames from "classnames";
import {IdValueFromConst, LabelValueFromConst} from "../../../Component/Handlers/ValueFromConst";
import {UserStatus} from "../Nurse/constant/userConst";
import Chip from "@material-ui/core/Chip";
import CrudMod from "../../../Component/Layouts/Button/CrudMod";

const useStyles = makeStyles({
    active: { color: 'white', background: '#32A232'},
    invited: { color: 'white', background: '#4884EF' },
    draft: { color: 'white', background: '#7F8DA0' },
    pending: { color: 'white', background: '#7F8DA0' },
    blocked: { color: 'white', background: '#b1b8be' }
});

const StatusField = props => {
    const classes = useStyles();
    const record = useRecordContext();

    console.log("record[props.source]");
    console.log(record[props.source]);

    let className = classnames({
        [classes.active]: record[props.source] === IdValueFromConst(ProviderListStatus, 'Active'),
        [classes.invited]: record[props.source] === IdValueFromConst(ProviderListStatus, 'Invited'),
        [classes.pending]: record[props.source] === IdValueFromConst(ProviderListStatus, 'Pending'),
        [classes.draft]: record[props.source] === IdValueFromConst(ProviderListStatus, 'Draft'),
        [classes.blocked]: record[props.source] === IdValueFromConst(ProviderListStatus, 'Blocked'),
    })

    return (
        <Chip
            className={className}
            label={LabelValueFromConst(ProviderListStatus, record[props.source], '-')}
        />
    );
};

const ListActions = ({basePath, className, ...rest}) => {
    return (<TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        <CreateButtonMod basePath={basePath} label={'Invite provider'} />
    </TopToolbar>);
};

const ListFilter = (props) => (
    <Filter {...props} >
        <TextInput variant="standard" label="Search by name: " source="name" alwaysOn />
        <TextInput variant="standard" label="Search by phone:" source="phone" alwaysOn />
        <TextInput variant="standard" label="Search by facility: " source="facilityName" alwaysOn />
        <SelectInput variant="standard" label="Credentials: " emptyText={"All"} emptyValue={''}  source="credentials" choices={ProviderCredentials} alwaysOn />
        <SelectInput variant="standard" label="Status: " emptyText={"All"} emptyValue={''}  source="status" choices={ProviderStatus} alwaysOn />
    </Filter>
);

export const ProviderList = ({permissions, ...props}) => (

    <List {...props}
          title="Providers"
          basePath="/provider/list"
          actions={<ListActions/>}
          filters={<ListFilter/>}
    >
        <Datagrid bulkActionButtons={false}>
            <TextField label='Name' source="name" sortBy="name" />
            <SelectField label="Credentials" source="credentials" choices={ProviderCredentials} />
            <TextField label='Phone' source="phone"/>
            <TextField label='Facilities' source="facility_count"/>
            <TextField label='Total logged chats' source="count_log" />
            <StatusField source="status" />
            <CrudMod show="true" edit="true" />
        </Datagrid>
    </List>
);