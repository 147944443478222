import * as React from "react";
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined';
import { Link } from 'react-router-dom';
import {Button, useRecordContext} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const AddEntityButton = ({ pathEdit, labelVal, keyPath, showLabel, css = {} }) => {
    const record = useRecordContext();

    return (<Button
            sx={{...{float: "right", border: '1px solid #4884EF', borderRadius: '4px', padding: '6px 16px', marginBottom: '8px'}, ...css}}
            component={Link}
            to={ '/' + pathEdit + (record ? ('?' + keyPath + '=' + record.id) : '') }
            label={labelVal}
            title={labelVal}
        >
            {showLabel ? <AddCircleOutlinedIcon /> : ''}
        </Button>
    );
};


export default AddEntityButton;