import React from 'react';
import {
    Datagrid,
    TextField,
    Filter,
    TextInput,
    DateField,
    SelectField,
    TopToolbar,
    sanitizeListRestProps, Button, useDataProvider, ShowButton, useRecordContext
} from 'react-admin';
import {ReportType, VisitStatus} from "../constant/const";
import {makeStyles} from "@material-ui/core/styles";
import classnames from "classnames";
import {IdValueFromConst, LabelValueFromConst} from "../../../../Component/Handlers/ValueFromConst";
import Chip from "@material-ui/core/Chip";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import List from "@material-ui/core/List";

const useStyles = makeStyles({
    finished: { color: 'white', background: '#32A232'},
    draft: { color: 'white', background: '#7F8DA0' }
});

const StatusField = props => {
    const classes = useStyles();
    const record = useRecordContext();

    let className = classnames({
        [classes.finished]: record[props.source] === IdValueFromConst(VisitStatus, 'Finished'),
        [classes.draft]: record[props.source] === IdValueFromConst(VisitStatus, 'Draft'),
    })

    return (
        <Chip
            className={className}
            label={LabelValueFromConst(VisitStatus, record[props.source], record[props.source])}
        />
    );
};

const ListActions = ({basePath, className, ...rest}) => {
    return (<TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {/*
        <AddEntityButton pathEdit='visit/problem/list' labelVal='List problems'/>
*/}
    </TopToolbar>);
};

const DownloadField = ({ record = {} }) => {
    const dataProvider = useDataProvider();

    const handleConfirm = () => {

        if(record.id){
            dataProvider
                .getOneWithParams('visit/getPDF', {'id' : record.id})
                .then((data) => {
                    const link = document.createElement("a");
                    link.href = data.data.link;
                    link.target = '_blank';
                    link.click();
                }).catch(error => {
                alert('Not found pdf');
            });
        }

    };

    return record.status === 'finished' ?
        (  <Button label={'pdf'} onClick={handleConfirm}>
                <ArrowDownwardIcon  />
            </Button>
        ) : null;
}

const ListFilter = (props) => (
    <Filter {...props} >
        <TextInput variant="standard" label="Patient: " source="patient" alwaysOn />
        <TextInput variant="standard" label="Provider: " source="provider" alwaysOn />
        <TextInput variant="standard" label="Facility: " source="facility" alwaysOn />
    </Filter>
);

export const BillerVisitList = ({permissions, ...props}) => (



    <List {...props}
          actions={<ListActions />}
          basePath="/visit/list/biller/{id}"
          filters={<ListFilter/>}
          perPage={20}
    >
        <Datagrid bulkActionButtons={false}>
            <TextField label='Id' source="id" />
            <DateField label='DOB' source="dob" />
            <TextField label='Patient' source="patient"/>
            <TextField label='Provider' source="provider"/>
            <TextField label='Facility' source="facility"/>
            <SelectField label="Report type" source="report" choices={ReportType} />
            <TextField label='CPT' source="cpt"/>
            <TextField label='ICD-10' source="icd"/>
            <StatusField source="status" />
            <DownloadField />
            <ShowButton />
        </Datagrid>
    </List>
);

