import React from 'react';
import {
    TextInput,
    Create,
    SimpleForm,
    required,
    SelectInput,
    ReferenceArrayInput, ArrayInput,
    AutocompleteInput, ReferenceInput, SelectArrayInput
} from 'react-admin';
import {AdminRole} from "./constant/AdminsConst";
import ActionsMod from "../../Component/Layouts/Button/ActionsMod";
import ToolBarButtonModCreate from "../../Component/Layouts/Button/ToolBarButtonModCreate";
import {makeStyles} from "@material-ui/core/styles";
import {FormDataConsumer} from "react-admin";
import {TemplatesLimit} from "../SmartText/ChiefComplaints/constant/ChiefComplaintConst";
import {FacilityStatus} from "../Facility/Facility/constant/facilityConst";

export const AdminsCreate = props => {
    return (<Create actions={<ActionsMod title={'Add User'}/>} {...props}>
        <SimpleForm toolbar={<ToolBarButtonModCreate showCancel="true" hrefCancel="/#/admins" textSave="Add"/>}
                    redirect="list" label="list"  encType="multipart/form-data">
            <TextInput fullWidth label="Full name" source="name" validate={required()}/>
            <SelectInput fullWidth label="Role" source="roles" id="adminRole" validate={required()}
                         choices={AdminRole}/>
            <TextInput fullWidth label="Email" source="email" validate={required()}/>
            <TextInput fullWidth label="Password" source="password" validate={required()}/>

            <FormDataConsumer fullWidth>
                {({formData, ...rest}) => {
                    return formData.roles ==='BILLER' ? <ActionsMod title={'Practices'}/> : null;
                }}
            </FormDataConsumer>

            <FormDataConsumer fullWidth>
                {({formData, ...rest}) => {
                    return formData.roles === 'BILLER' ?
                        (<ReferenceArrayInput fullWidth label="Practices" source="practiceIds" reference="practices/list"
                                              validate={required()}>
                            <SelectArrayInput fullWidth variant={'standard'} optionText="name" />
                        </ReferenceArrayInput>) : null;
                }}
            </FormDataConsumer>

            <FormDataConsumer fullWidth>
                {({formData, ...rest}) => {
                    return formData.roles === 'FACILITY_OWNER' ?
                        (<ReferenceArrayInput fullWidth label="Facilities" source="facilityIds" reference="facility/list"
                                         validate={required()}>
                            <SelectArrayInput variant={'standard'} fullWidth optionText="name" />
                        </ReferenceArrayInput>) : null;
                }}
            </FormDataConsumer>

        </SimpleForm>
    </Create>)
};

