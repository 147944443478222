import React from 'react';
import {
    TextInput,
    Edit,
    SimpleForm,
    SelectInput,
    required,
    FormDataConsumer,
    ReferenceArrayInput,
    SelectArrayInput
} from 'react-admin';
import {AdminRole} from "./constant/AdminsConst";
import ActionsMod from "../../Component/Layouts/Button/ActionsMod";
import ToolBarButtonModEdit from "../../Component/Layouts/Button/ToolBarButtonModEdit";

export const AdminsEdit = props => {

    return (<Edit actions={<ActionsMod title={'Edit User'} />} {...props} undoable={false}>
        <SimpleForm toolbar={<ToolBarButtonModEdit showDel="true" showCancel="true" hrefCancel="/#/admins"/>} variant="outlined" encType="multipart/form-data">
            <TextInput fullWidth label="Full name" source="name" validate={required()}/>
            <SelectInput fullWidth label="Role" source="roles" validate={required()} choices={AdminRole} />
            <TextInput fullWidth label="Email" source="email" validate={required()}/>
            <TextInput fullWidth label="New password" source="password" validate={required()}/>

            <FormDataConsumer fullWidth>
                {({formData, ...rest}) => {
                    return formData.roles ==='BILLER' ? <ActionsMod title={'Practices'}/> : null;
                }}
            </FormDataConsumer>

            <FormDataConsumer fullWidth>
                {({formData, ...rest}) => {
                    return formData.roles === 'BILLER' ?
                        (<ReferenceArrayInput fullWidth label="Practices" source="practiceIds" reference="practices/list"
                                              validate={required()}>
                            <SelectArrayInput fullWidth variant={'standard'} optionText="name" />
                        </ReferenceArrayInput>) : null;
                }}
            </FormDataConsumer>

            <FormDataConsumer fullWidth>
                {({formData, ...rest}) => {
                    return formData.roles === 'FACILITY_OWNER' ?
                        (<ReferenceArrayInput fullWidth label="Facilities" source="facilityIds" reference="facility/list"
                                              validate={required()}>
                            <SelectArrayInput variant={'standard'} fullWidth optionText="name" />
                        </ReferenceArrayInput>) : null;
                }}
            </FormDataConsumer>

        </SimpleForm>
    </Edit>);
};
