import * as React from "react";
import {Button, CreateButton} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const BackButton = props => {
    const {label, link} = props;

    return (
        <Button
            sx={{
                color: '#4884EF',
                border: '1px solid #4884EF',
                borderRadius: '4px',
                padding: '6px 16px',
                float: 'right',
                margin: '2rem 0'
            }}
            component={Link}
            to={{pathname: link }}
            label={label || 'Back'}
            title={label || 'Back'}
        >
        </Button>
    );
};

export default BackButton;